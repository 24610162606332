import { Link } from "react-router-dom";

const pricing_data = [
  {
    id: 1,
    pricing_plan: [
      // {
      //   price_title: "Career Counseling",
      //   price_subtitle: "starts from",
      //   price: 999,
      //   navige_link: "/main-payment-page",
      //   price_features: [
      //     "Soft skills sssessment",
      //     "Aptitude assessment",
      //     "AI interview with report",
      //     "30 minute 1:1 with career counselor",
      //   ],
      // },

      // {
      //   price_title: "Interview Preparation",
      //   price_subtitle: "starts from",
      //   price: 1999,
      //   navige_link: "/main-payment-page",
      //   price_features: [
      //     "Soft skills assessment",
      //     "Aptitude assessment",
      //     "Resume building",
      //     "AI interview with report",
      //     "Mock interview",
      //     "30 minute 1:1 with interviewer",
      //     "Placement support",
      //   ],
      // },

      // {
      //   tag: true,
      //   price_title: "Soft Skills Building and Placement Readiness",
      //   price_subtitle: "starts from",
      //   price: 4999,
      //   navige_link: "/main-payment-page",
      //   price_features: [
      //     "4 week live coaching",
      //     "Soft skills assessment",
      //     "Aptitude assessment",
      //     "Resume building",
      //     "Group discussion",
      //     "AI interview with report",
      //     "Mock interview",
      //     "Placement support",
      //   ],
      // },
      {
        price_title: "Custom Package",
        price_subtitle: "",
        price: 0,
        navige_link: "/custom-payment-page",
        price_features: [
          "Personal branding",
          "Communication and confidence",
          "Interview preparation",
          "Soft skills evaluation",
          "Aptitude assessment",
          "ATS resume building",
          "Personality assessment",
          "AI interview",
          "Mock interview",
        ],
      },
    ],
  },
];

const Pricing = () => {
  return (
    <>
      <section className="price__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 offset-xxl-4">
              <div
                style={{ paddingTop: 30 }}
                className="section__title-wrapper mb-60 text-center"
              >
                <h2 className="section__title">
                  Our{" "}
                  <span className="yellow-bg yellow-bg-big">
                    Package
                    <img src="/assets/img/shape/yellow-bg.png" alt="" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="price__tab-content">
                <div className="tab-content" id="nav-tabContent">
                  <div className="row">
                    <div className="col-md-8">
                      <img src="/assets/img/ghimages/soft1.jpg" />
                    </div>
                    <div className="col-md-4">
                      {pricing_data.map((item, index) => (
                        <div className="row">
                          {item.pricing_plan.map((price, index) => (
                            <div key={index} className="">
                              <div
                                className="price__item grey-bg mb-30 p-relative"
                                style={{ padding: "20px" }}
                              >
                                {price.tag && (
                                  <div className="price__offer">
                                    <span>Best Value</span>
                                  </div>
                                )}
                                <div className="price__head">
                                  <h4>{price.price_title}</h4>
                                </div>
                                <div className="price__features mb-40">
                                  <ul>
                                    {price.price_features.map(
                                      (feature, index) => (
                                        <li key={index}>
                                          {!feature == "" ? (
                                            <>
                                              <i className="far fa-check"></i>
                                              {feature}
                                            </>
                                          ) : null}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                                <p>* Above Services can be customized</p>
                                <Link
                                  to="/custom-payment-page"
                                  state={{ data: price }}
                                  className={`e-btn ${
                                    index === 1 ? "e-btn-4" : "e-btn-border"
                                  }`}
                                >
                                  Get Started
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
