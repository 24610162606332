import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/index.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/context/AuthProvider';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );



const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.Fragment>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.Fragment>
);

reportWebVitals();













// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
  // <React.StrictMode>
  // <BrowserRouter>
  // <AuthProvider>
  
  //   <Routes>
  //     <Route path="/*" element={<App/>}/>
  //   </Routes>
  
  // </AuthProvider>
  // </BrowserRouter>
  // </React.StrictMode>
  // <React.Fragment>
  //   <App/>
  // </React.Fragment>
//);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
