import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import React, { useEffect, useState } from "react";

import {
  razorpayCreateOrder,
  razorpayCreateOrderWithSplitPayment,
} from "../../services/payment-service";
import Alert from "react-bootstrap/Alert";
import { addserviceScheduler } from "../../services/scheduler-service";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";

import {
  AddStudentCouponRecordService,
  checkIsCouponValid,
} from "../../services/coupon-service";
import "./homepaymentpage.css";
import JobsPopupLoginForm from "../../pages/jobs/jobs-popup-login-form";
import Modal from "react-modal";
import ReviewVideoGallery from "./review-video-gallery";
import PackageItem from "./package-item";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
const HomePagePayment = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
    },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentSource, setCurrentSource] = useState();

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  const location = useLocation();

  const [selectedpackageprice, setselectedpackageprice] = useState("");

  const [isLoggedInStatus, setisLoggedInStatus] = useState(false);
  const [currentStudentDetails, setCurrentStudentDetails] = useState();

  let checkLoginStatus = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    setCurrentStudentDetails(userDetials);
    let token = userDetials.token;
    console.log("token", token);
    if (token) {
      setisLoggedInStatus(true);
    }
    console.log("isLoggedInStatus", isLoggedInStatus);
  };

  useEffect(() => {
    checkLoginStatus();
    console.log("current href", window.location.host);
    setCurrentSource(window.location.host);
  }, []);

  useEffect(() => {
    console.log(location, " useLocation Hook");
    const selectPackage = location.state?.data;
    console.log("selectPackage", selectPackage);
    const selectedprice = selectPackage.price;
    console.log("selectedprice", selectedprice);
    setselectedpackageprice(selectedprice);
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log("selectedpackageprice", selectedpackageprice);

    if (discountvalue) {
      console.log("Entered on the discount loop");
      //Step 1 Find discount value
      const final_discount_value = Math.round(
        selectedpackageprice * (discountvaluefromresp / 100)
      );
      console.log("final_discount_value", final_discount_value);

      //Step 2 Find value after reducing the discount
      const getFinalValue = selectedpackageprice - final_discount_value;
      console.log("getFinalValue", getFinalValue);

      //Step 3 Find the Razorpay portal charges for price after discount
      const razorpayWIthGST = Number(getFinalValue * 2.7) / 100;
      console.log("razorpayWIthGST", razorpayWIthGST);

      //Step 4 Add Razorpay portal charges upfront
      const Price_with_razorpay_upfront = getFinalValue + razorpayWIthGST;
      console.log("Price_with_razorpay_upfront", Price_with_razorpay_upfront);

      //Step 5 Mentor Share
      //dont add razorpay value in this
      const mentor_share = Number(getFinalValue * 88.2) / 100;

      const payload = {
        amount: Number(Price_with_razorpay_upfront) * 100,
        discount_code_used: coupondetails.coupon_code_submitted,
        mygreenhorn_amount: "",
        mentor_amount: Number(mentor_share) * 100,
        //amount: 100,
      };
      console.log("payload", payload);
      const result = await razorpayCreateOrderWithSplitPayment(payload)
        .then((res) => {
          console.log(res);
          let response = res;
          const id = response.id;
          const options = {
            currency: "INR",
            name: "GreenHorn",
            order_id: id,
            description: "GreenHorn Transaction",
            handler: async function (response) {
              const data = {
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
              console.log(response);
              const userDetials = getDataFromLocalStorage("user-details");
              console.log(userDetials);
              const email = userDetials.email;
              const payload = {
                username: userDetials.userName,
                servicebooked: "Homepage Payment Packages",
                bookingDate: new Date(),
                amount: selectedpackageprice,
                source: currentSource,
                discount_code_used: coupondetails.coupon_code_submitted,
                mentor_amount: Number(mentorSplitAmount * 88.2) / 100,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
              const schedulerResult = await addserviceScheduler(payload)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
              const coupon_payload = {
                mentor_coupon_code: coupondetails.coupon_code_submitted,
                mentor_name: "",
                mentorEmailId: "",
                generation_date: new Date(),
                StudentEmailId: currentStudentDetails.userName,
                StudentName: currentStudentDetails.name,
                PackageSelected: selectedpackageprice,
              };
              const Couponrecordresult = await AddStudentCouponRecordService(
                coupon_payload
              )
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });

              console.log(schedulerResult);
              console.log("Couponrecordresult", Couponrecordresult);
              if (response) {
                toast.success(
                  `Payment Successfully Completed, Please check your mail for more detials `,
                  {
                    position: "top-left",
                  }
                );
              }
            },
            theme: {
              color: "#61dafb",
            },
          };

          const paymentObject = new window.Razorpay(options);

          paymentObject.open();
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(result);
    }
  }

  const [coupondetails, setCouponDetails] = useState({
    coupon_code_submitted: "",
  });

  const [discountvalue, setDiscountvalue] = useState();
  const [discountvaluefromresp, setDiscountvaluefromresp] = useState();

  let couponDetailsonChange = (evt) => {
    console.log("evt", evt.target.value);
    setCouponDetails({
      ...coupondetails,
      [evt.target.name]: evt.target.value,
    });
  };

  const [mentorSplitAmount, setMentorSplitAmount] = useState();
  const [discountValuetoshow, setDiscountvaluetoshow] = useState();

  const checkIsCouponValidfunction = async () => {
    const couponobj = {
      studentEmailId: "",
      coupon_code: coupondetails.coupon_code_submitted,
      package_selected: "",
    };
    const checkresult = await checkIsCouponValid(couponobj)
      .then((resp) => {
        const checkresp = resp.checkresult;
        //Step 1 Find discount value
        const discount_value = checkresp.discount_value;
        console.log("discount_value", discount_value);
        setDiscountvaluefromresp(discount_value);
        const final_discount_value = Math.round(
          selectedpackageprice * (discount_value / 100)
        );
        console.log("final_discount_value", final_discount_value);

        //Step 2 Find value after reducing the discount
        const getFinalValue = selectedpackageprice - final_discount_value;
        console.log("getFinalValue", getFinalValue);

        setDiscountvaluetoshow(getFinalValue);

        //Step 3 Find the Razorpay portal charges for price after discount
        const razorpayWIthGST = Number(getFinalValue * 2.7) / 100;
        console.log("razorpayWIthGST", razorpayWIthGST);

        //Step 4 Add Razorpay portal charges upfront
        const Price_with_razorpay_upfront = getFinalValue + razorpayWIthGST;
        console.log("Price_with_razorpay_upfront", Price_with_razorpay_upfront);
        //Step 5 Find the GST cost for MygreenHorn with razorpay charges

        setMentorSplitAmount(getFinalValue);

        //Step 6 Final Costing with all above
        // const getFinalValueWithGST = Price_with_razorpay_upfront + GSTCost;
        const getFinalValueWithGST = Price_with_razorpay_upfront;
        console.log("getFinalValueWithGST", getFinalValueWithGST);

        setDiscountvalue(getFinalValueWithGST);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Wrapper>
      <SEO pageTitle={"Homepage Payment"} />
      <>
        <Container style={{ backgroundColor: "white" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Card className="carddesign">
              <Card.Body>
                <Row>
                  <Col sm={8}>
                    <ReviewVideoGallery />
                  </Col>
                  <Col>
                    <PackageItem
                      selectedPackagePrice={selectedpackageprice}
                      discountValue={discountvalue}
                      discountValueToShow={discountValuetoshow}
                      isLoggedInStatus={isLoggedInStatus}
                      modalIsOpen={modalIsOpen}
                      afterOpenModal={afterOpenModal}
                      closeModal={closeModal}
                      customStyles={customStyles}
                      displayRazorpay={displayRazorpay}
                      openModal={openModal}
                      coupondetails={coupondetails}
                      couponDetailsonChange={couponDetailsonChange}
                      checkIsCouponValidfunction={checkIsCouponValidfunction}
                      packageDetails={{
                        title: "Career Counseling",
                        price: 999,
                        features: [
                          "Soft Skills Assessment",
                          "Aptitude Assessment",
                          "AI Interview with Report",
                          "30 minute 1:1 with Career Counselor",
                        ],
                        customizationText: "Above Services can be customized",
                      }}
                    />

                    {/* {selectedpackageprice === 999 ? (
                      <div
                        className="price__item grey-bg mb-30 p-relative"
                        style={{ padding: "20px" }}
                      >
                        <div className="price__head">
                          <h3>Career Counseling</h3>
                        </div>
                        {!discountvalue ? (
                          <>
                            <div className="price__tag mb-25">
                              <h4>
                                {" "}
                                ₹ 999
                                <span style={{ fontSize: 16 }}>
                                  &nbsp;(+ 2.7% Online Transaction fee)
                                </span>
                              </h4>
                            </div>
                          </>
                        ) : null}
                        {discountvalue ? (
                          <div className="price__tag mb-25">
                            <div class="style-1">
                              <del>
                                <span class="amount">₹ 999</span>
                              </del>
                              <ins>
                                <span class="amount">
                                  ₹ {discountValuetoshow}
                                </span>
                              </ins>
                              <br />
                              <span style={{ fontSize: 16 }}>
                                &nbsp;(+ 2.7% Online Transaction fee)
                              </span>
                            </div>
                          </div>
                        ) : null}

                        <div className="price__features mb-40">
                          <ul>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Soft Skills
                              Assessment
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Aptitude
                              Assessment
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>AI Interview with
                              Report
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>30 minute 1:1 with
                              Career Counselor
                            </li>
                          </ul>
                        </div>
                        <p>* Above Services can be customized</p>

                        {isLoggedInStatus === true ? (
                          <>
                            <div className="text-center">
                              <button
                                className="e-btn e-btn-border"
                                style={{ marginTop: "5px" }}
                                onClick={displayRazorpay}
                              >
                                Book Now
                              </button>
                            </div>
                            <p>
                              <span style={{ color: "black", fontSize: 18 }}>
                                {" "}
                                Final Price :{" "}
                              </span>
                              <span style={{ color: "green", fontSize: 18 }}>
                                {discountvalue}
                              </span>{" "}
                              (
                              <span style={{ color: "green" }}>
                                {discountValuetoshow}
                              </span>
                              + 2.7% Transcation fee)
                            </p>
                          </>
                        ) : null}
                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                          overlayClassName="Overlay"
                        >
                          <JobsPopupLoginForm />
                        </Modal>
                        {isLoggedInStatus === false ? (
                          <>
                            <div className="text-center">
                              <button
                                className="e-btn e-btn-border"
                                style={{ marginTop: "5px" }}
                                onClick={openModal}
                              >
                                Login
                              </button>
                            </div>
                            <p style={{ color: "red" }}>
                              * Please Login to Complete Payment
                            </p>
                          </>
                        ) : null}
                        <p>Have a Coupon code ?</p>

                        {isLoggedInStatus === true ? (
                          <>
                            <input
                              placeholder="Coupon Code"
                              className="form-control marginTop5"
                              type="text"
                              name="coupon_code_submitted"
                              value={coupondetails.coupon_code_submitted}
                              onChange={couponDetailsonChange}
                            />

                            <button
                              className="e-btn e-btn-border"
                              style={{ marginTop: "5px" }}
                              onClick={checkIsCouponValidfunction}
                            >
                              Apply Now
                            </button>
                          </>
                        ) : (
                          <>
                            {" "}
                            <input
                              placeholder="Coupon Code"
                              className="form-control marginTop5"
                              type="text"
                              name="coupon_code_submitted"
                              value={coupondetails.coupon_code_submitted}
                              onChange={couponDetailsonChange}
                              disabled
                            />
                            <p style={{ color: "red" }}>
                              * Please Login to apply coupons
                            </p>
                            <button
                              className="e-btn e-btn-border"
                              style={{ marginTop: "5px" }}
                              disabled
                            >
                              Apply Now
                            </button>
                          </>
                        )}
                      </div>
                    ) : null} */}

                    {selectedpackageprice === 1999 ? (
                      <div
                        className="price__item grey-bg mb-30 p-relative"
                        style={{ padding: "20px" }}
                      >
                        <div className="price__head">
                          <h3>Interview Preparation</h3>
                        </div>
                        {!discountvalue ? (
                          <>
                            <div className="price__tag mb-25">
                              <h4>
                                {" "}
                                ₹ 1999
                                <span style={{ fontSize: 16 }}>
                                  &nbsp;(add 2.7% Payment Gateway fee)
                                </span>
                              </h4>
                            </div>
                          </>
                        ) : null}
                        {discountvalue ? (
                          <div className="price__tag mb-25">
                            <div class="style-1">
                              <del>
                                <span class="amount">₹ 1999</span>
                              </del>
                              <ins>
                                <span class="amount">
                                  ₹ {discountValuetoshow}
                                </span>
                              </ins>
                              <br />
                              <span style={{ fontSize: 16 }}>
                                &nbsp;(add 2.7% Payment Gateway fee)
                              </span>
                            </div>
                          </div>
                        ) : null}

                        <div className="price__features mb-40">
                          <ul>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Soft Skills
                              Assessment
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Aptitude
                              Assessment
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Resume Building
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>AI Interview with
                              Report
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Mock Interview
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>30 minute 1:1 with
                              Career Counselor
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Placement Support
                            </li>
                          </ul>
                        </div>
                        <p>* Above Services can be customized</p>

                        {isLoggedInStatus === true && !discountvalue ? (
                          <>
                            <div className="text-center">
                              <OverlayTrigger
                                delay={{ hide: 450, show: 300 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    please use mentor coupon code to proceed
                                  </Tooltip>
                                )}
                                placement="bottom"
                              >
                                <Button className="e-btn e-btn-border">
                                  Book Now
                                </Button>
                              </OverlayTrigger>
                            </div>
                            <p>
                              <span style={{ color: "black", fontSize: 18 }}>
                                Final Price :{" "}
                              </span>
                              <span style={{ color: "green", fontSize: 18 }}>
                                {discountvalue}
                              </span>{" "}
                              (
                              <span style={{ color: "green" }}>
                                {discountValuetoshow}
                              </span>
                              add 2.7% Transcation fee)
                            </p>
                          </>
                        ) : null}
                        {isLoggedInStatus === true && discountvalue ? (
                          <>
                            <div className="text-center">
                              <button
                                className="e-btn btn-success"
                                style={{
                                  marginTop: "5px",
                                  background: "green",
                                }}
                                onClick={displayRazorpay}
                              >
                                Book Now
                              </button>
                            </div>
                            <p>
                              <span style={{ color: "black", fontSize: 18 }}>
                                Final Price :{" "}
                              </span>
                              <span style={{ color: "green", fontSize: 18 }}>
                                {discountvalue}
                              </span>{" "}
                              (
                              <span style={{ color: "green" }}>
                                {discountValuetoshow}
                              </span>
                              add 2.7% Transcation fee)
                            </p>
                          </>
                        ) : null}
                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                          overlayClassName="Overlay"
                        >
                          <JobsPopupLoginForm />
                        </Modal>
                        {isLoggedInStatus === false ? (
                          <>
                            <div className="text-center">
                              <button
                                className="e-btn e-btn-border"
                                style={{ marginTop: "5px" }}
                                onClick={openModal}
                              >
                                Login
                              </button>
                            </div>
                            <p style={{ color: "red" }}>
                              * Please Login to Complete Payment
                            </p>
                          </>
                        ) : null}

                        {isLoggedInStatus === true ? (
                          <>
                            <p>Have a Coupon code ?</p>
                            <input
                              placeholder="Coupon Code"
                              className="form-control marginTop5"
                              type="text"
                              name="coupon_code_submitted"
                              value={coupondetails.coupon_code_submitted}
                              onChange={couponDetailsonChange}
                            />

                            <button
                              className="e-btn e-btn-border"
                              style={{ marginTop: "5px" }}
                              onClick={checkIsCouponValidfunction}
                            >
                              Apply Now
                            </button>
                          </>
                        ) : null}
                      </div>
                    ) : null}

                    {selectedpackageprice === 4999 ? (
                      <div
                        className="price__item grey-bg mb-30 p-relative"
                        style={{ padding: "20px" }}
                      >
                        <div className="price__head">
                          <h3>Soft Skills Building and Placement Readiness</h3>
                        </div>
                        {!discountvalue ? (
                          <>
                            <div className="price__tag mb-25">
                              <h4>
                                ₹ 4999
                                <span style={{ fontSize: 16 }}>
                                  &nbsp;(+ 2.7% Online Transaction fee)
                                </span>
                              </h4>
                            </div>
                          </>
                        ) : null}
                        {discountvalue ? (
                          <div className="price__tag mb-25">
                            <div class="style-1">
                              <del>
                                <span class="amount">₹ 4999</span>
                              </del>
                              <ins>
                                <span class="amount">
                                  ₹ {discountValuetoshow}
                                </span>
                              </ins>
                              <br />
                              <span style={{ fontSize: 16 }}>
                                &nbsp;(+ 2.7% Online Transaction fee)
                              </span>
                            </div>
                          </div>
                        ) : null}

                        <div className="price__features mb-40">
                          <ul>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>4 week live
                              coaching
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Soft Skills
                              Assessment
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Aptitude
                              Assessment
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Resume Building
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Group Discussion
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>AI Interview with
                              Report
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Mock Interview
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>30 minute 1:1 with
                              Career Counselor
                            </li>
                            <li>
                              {" "}
                              <i className="far fa-check"></i>Placement Support
                            </li>
                          </ul>
                        </div>
                        <p>* Above Services can be customized</p>
                        {isLoggedInStatus === true ? (
                          <>
                            <div className="text-center">
                              <button
                                className="e-btn e-btn-border"
                                style={{ marginTop: "5px" }}
                                onClick={displayRazorpay}
                              >
                                Book Now
                              </button>
                            </div>
                            <p>
                              <span style={{ color: "black", fontSize: 18 }}>
                                {" "}
                                Final Price :{" "}
                              </span>
                              <span style={{ color: "green", fontSize: 18 }}>
                                {discountvalue}
                              </span>{" "}
                              (
                              <span style={{ color: "green" }}>
                                {discountValuetoshow}
                              </span>
                              + 2.7% Transcation fee)
                            </p>
                          </>
                        ) : null}
                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                          overlayClassName="Overlay"
                        >
                          <JobsPopupLoginForm />
                        </Modal>
                        {isLoggedInStatus === false ? (
                          <>
                            <div className="text-center">
                              <button
                                className="e-btn e-btn-border"
                                style={{ marginTop: "5px" }}
                                onClick={openModal}
                              >
                                Login
                              </button>
                            </div>
                            <p style={{ color: "red" }}>
                              * Please Login to Complete Payment
                            </p>
                          </>
                        ) : null}
                        <p>Have a Coupon code ?</p>

                        {isLoggedInStatus === true ? (
                          <>
                            <input
                              placeholder="Coupon Code"
                              className="form-control marginTop5"
                              type="text"
                              name="coupon_code_submitted"
                              value={coupondetails.coupon_code_submitted}
                              onChange={couponDetailsonChange}
                            />

                            <button
                              className="e-btn e-btn-border"
                              style={{ marginTop: "5px" }}
                              onClick={checkIsCouponValidfunction}
                            >
                              Apply Now
                            </button>
                          </>
                        ) : (
                          <>
                            {" "}
                            <input
                              placeholder="Coupon Code"
                              className="form-control marginTop5"
                              type="text"
                              name="coupon_code_submitted"
                              value={coupondetails.coupon_code_submitted}
                              onChange={couponDetailsonChange}
                              disabled
                            />
                            <p style={{ color: "red" }}>
                              * Please Login to apply coupons
                            </p>
                            <button
                              className="e-btn e-btn-border"
                              style={{ marginTop: "5px" }}
                              disabled
                            >
                              Apply Now
                            </button>
                          </>
                        )}
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </>
    </Wrapper>
  );
};

export default HomePagePayment;
