import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useCourses from "../../../hooks/use-courses";
import { selectCourses } from "../../../redux/features/course-slice";
import { getCourseListing } from "../../../services/learning-service";
import { Grid, List } from "../../../svg";
import CourseGrid from "./course-grid";
import CourseList from "./course-list";

const AllCourses = ({ list }) => {
  const courses = useSelector(selectCourses);
  const { allCourses, setAllCourses } = useCourses();
  const short_filter = ["Default", "Featured", "Free", "Paid", "Trending"];

  const handleChange = (e) => {
    if (e.target.value === "Default") {
      setAllCourses(courses);
    }
    if (e.target.value === "Featured") {
      setAllCourses(courses.filter((item) => item.featured));
    }
    if (e.target.value === "Free") {
      setAllCourses(courses.filter((item) => item.price === "free"));
    }
    if (e.target.value === "Paid") {
      setAllCourses(courses.filter((item) => item.price !== "free"));
    }
    if (e.target.value === "Trending") {
      setAllCourses(courses.filter((item) => item.trending));
    }
  };

  const [courseList, setCourseList] = useState([]);
  const [AllCourseList, setAllCourseList] = useState([]);
  useEffect(() => {
    // tslint:disable-next-line:no-unused-vars
    const resp = getCourseListing()
      .then((res) => {
        console.log(res);
        let reverseres = res.reverse();
        setAllCourseList(reverseres);
        let result = reverseres.filter(function (item) {
          console.log(item.course_category, "Information Technology");
          return (
            item.course_category === "IT" ||
            item.course_category === "HR" ||
            item.course_category === "Sales" ||
            item.course_category === "Business Operations" ||
            item.course_category === "Marketing" ||
            item.course_category === "Engineering"
          );
        });

        // console.log('result', result)
        // setCourseList(result)
        setCourseList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <section
        className="page__title-area page__title-height page__title-overlay d-flex align-items-center"
        style={{
          background: `url(/assets/img/ghimages/try/2.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper mt-110">
                <h3 className="page__title">Learning</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb end */}
      <section className="course__area pt-120 pb-120">
        <div className="container">
          <div className="course__tab-inner grey-bg-2 mb-50">
            <div className="row align-items-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="course__tab-wrapper d-flex align-items-center">
                  <div className="course__tab-btn">
                    <ul className="nav nav-tabs" id="courseTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${!list && "active"}`}
                          id="grid-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#grid"
                          type="button"
                          role="tab"
                          aria-controls="grid"
                          aria-selected="true"
                        >
                          <Grid />
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link list ${list && "active"}`}
                          id="list-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#list"
                          type="button"
                          role="tab"
                          aria-controls="list"
                          aria-selected="false"
                        >
                          <List />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="course__view">
                    <h4>
                      Showing 1 - {AllCourseList.length} of{" "}
                      {AllCourseList.length}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="course__sort d-flex justify-content-sm-end">
                  <div className="course__sort-inner">
                    <select onChange={handleChange}>
                      {short_filter.map((category, index) => (
                        <option key={index}>{category}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div className="course__tab-conent">
                <div className="tab-content" id="courseTabContent">
                  <div
                    className={`tab-pane fade ${!list && "show active"}`}
                    id="grid"
                    role="tabpanel"
                    aria-labelledby="grid-tab"
                  >
                    <div className="row">
                      <CourseGrid itemsPerPage={6} items={AllCourseList} />
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${list && "show active"}`}
                    id="list"
                    role="tabpanel"
                    aria-labelledby="list-tab"
                  >
                    <div className="row">
                      <CourseList itemsPerPage={4} items={AllCourseList} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Free Course */}

      <section className="course__area pt-120 pb-120">
        <div className="container">
          <div className="section__title-wrapper mb-45">
            <h2 className="section__title">
              Best Free{" "}
              <span className="yellow-bg">
                Courses <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
              </span>
              Collection
            </h2>
          </div>
          <div className="course__tab-inner grey-bg-2 mb-50">
            <div className="row align-items-center">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="course__tab-wrapper d-flex align-items-center">
                  <div className="course__tab-btn">
                    <ul className="nav nav-tabs" id="courseTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${!list && "active"}`}
                          id="grid-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#grid"
                          type="button"
                          role="tab"
                          aria-controls="grid"
                          aria-selected="true"
                        >
                          <Grid />
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link list ${list && "active"}`}
                          id="list-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#list"
                          type="button"
                          role="tab"
                          aria-controls="list"
                          aria-selected="false"
                        >
                          <List />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="course__view">
                    <h4>
                      Showing 1 - {AllCourseList.length} of{" "}
                      {AllCourseList.length}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="course__sort d-flex justify-content-sm-end">
                  <div className="course__sort-inner">
                    <select onChange={handleChange}>
                      {short_filter.map((category, index) => (
                        <option key={index}>{category}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div className="course__tab-conent">
                <div className="tab-content" id="courseTabContent">
                  <div
                    className={`tab-pane fade ${!list && "show active"}`}
                    id="grid"
                    role="tabpanel"
                    aria-labelledby="grid-tab"
                  >
                    <div className="row">
                      <CourseGrid itemsPerPage={6} items={AllCourseList} />
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${list && "show active"}`}
                    id="list"
                    role="tabpanel"
                    aria-labelledby="list-tab"
                  >
                    <div className="row">
                      <CourseList itemsPerPage={4} items={AllCourseList} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllCourses;
