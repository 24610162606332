import React from 'react';

const Video = () => {
  return (
    <svg viewBox="0 0 24 24">
      <polygon className="st0" points="23,7 16,12 23,17 " />
      <path className="st0" d="M3,5h11c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2H3c-1.1,0-2-0.9-2-2V7C1,5.9,1.9,5,3,5z" />
    </svg>
  );
};

export default Video;