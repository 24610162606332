import { Link } from "react-router-dom";
import {
  Academics,
  ArtDesign,
  Business,
  DataScience,
  Finance,
  HealthFitness,
  Lifestyle,
  Marketing,
  Music,
} from "../../svg";

const category_data = [
  {
    id: 1,
    icon: <DataScience />,
    title: "Customized Campus Readiness Module",
    subtitle:
      "Our strength lies in customization of training module,basis the need of the Institution, tailer made to suit the students",
  },
  {
    id: 2,
    icon: <Business />,
    title: "Behavioural Training",
    subtitle:
      "Pre-training Psychometric tests gives us the edge to design and deliver need-based modules and behavioral training interventions",
  },
  {
    id: 3,
    icon: <ArtDesign />,
    title: "Industry Practitioners & Coaches",
    subtitle:
      "The first in India to deploy working professionals from the industry to intervene niche workshops, our coaches are well-known across India and have created a strong individual brand",
  },
];
const category_data2 = [
  {
    id: 4,
    icon: <Lifestyle />,
    title: "Faculty Development Workshop",
    subtitle:
      "We give equal focus on bringing the best Industry Practices to the floor for the faculties, both for behavioural & technical interventions",
  },
  {
    id: 5,
    icon: <Marketing />,
    title: "Focused workshops for unplaced students (ReInvent)",
    subtitle:
      "We don’t give up till the last student of the institution is placed. We conduct focused group interventions to identify and fix gaps. This is the biggest hallmark of our success",
  },
];

const CategoryArea = () => {
  return (
    <>
      <section className="category__area pt-120 pb-70">
        <div className="container">
          <div className="row align-items-end">
            <div className="">
              <div className="section__title-wrapper mb-45 text-center">
                <h2 className="section__title">
                  <span className="yellow-bg">
                    What we help you with{" "}
                    <img src="/assets/img/shape/yellow-bg-2.png" alt="" />{" "}
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {category_data.map((category) => (
              <div
                key={category.id}
                className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6"
              >
                <div
                  style={{ padding: 20, height: 280 }}
                  className="category__item text-center mt-10"
                >
                  <div className="text-center">
                    <div className="category__icon mr-30">{category.icon}</div>
                  </div>
                  <div className=" mb-30 transition-3 d-flex align-items-center mt-20">
                    <div className="category__content">
                      <h4 className="category__title">{category.title}</h4>
                      <p className="mt-20">{category.subtitle}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row mt-30 text-center">
            <div className="col-md-2"></div>
            {category_data2.map((category) => (
              <div key={category.id} className="col-md-4">
                <div
                  style={{ padding: 20, height: 280 }}
                  className="category__item text-center mt-10"
                >
                  <div className="text-center">
                    <div className="category__icon mr-30">{category.icon}</div>
                  </div>
                  <div className=" mb-30 transition-3 d-flex align-items-center mt-20">
                    <div className="category__content">
                      <h4 className="category__title">{category.title}</h4>
                      <p className="mt-20">{category.subtitle}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryArea;
