import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";

import "./studentinteractivedashboard.css";

import { Link, useNavigate, useLocation } from "react-router-dom";

import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import config from "../../services/config";
import { quickInterviewToken } from "../../services/quick-interview";
import styled from "styled-components";

import { razorpayCreateOrder } from "../../services/payment-service";
import { addserviceScheduler } from "../../services/scheduler-service";

import SliderMenu from "./slidermenu";

const StudentInteractiveDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [ToGOTO, setToGOTO] = useState("");

  useEffect(() => {
    const Path = location.state?.data;
    console.log("Path", Path);
    if (Path) {
      setToGOTO(Path);
      navigate(Path);
    }
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      slidesToSlide: 7, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const Video = styled.iframe`
    display: flex;
    align-items: center;
    border: none;
    max-width: 490px;
    width: 100%;
    height: 100%;
    max-height: 276px;
    min-height: 276px;
    box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
  `;

  const opts = {
    width: "100%",
    height: "300",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const quickInterview = () => {
    quickInterviewToken().then((token) => {
      window.location.href = config.QUICK_INTERVIEW + token;
    });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const payload = { amount: 99900 };

    const result = await razorpayCreateOrder(payload)
      .then((res) => {
        console.log(res);
        let response = res;
        const id = response.id;
        const options = {
          currency: "INR",
          name: "GreenHorn",
          order_id: id,
          description: "GreenHorn Transaction",
          handler: async function (response) {
            const data = {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            console.log(response);

            const userDetials = getDataFromLocalStorage("user-details");
            console.log(userDetials);
            const email = userDetials.email;
            const payload = {
              username: userDetials.userName,
              servicebooked: "Special Introductory Package",
              bookingDate: new Date(),
              amount: "999",
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
            };
            const schedulerResult = await addserviceScheduler(payload)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });

            console.log(schedulerResult);
            if (response) {
              toast.success(
                `Payment Successfully Completed, Please check your mail for more detials`,
                {
                  position: "top-left",
                }
              );
            }
          },
          theme: {
            color: "#61dafb",
          },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.open();
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(result);
  }

  return (
    <>
      <Row style={{ background: "#ffffff" }}>
        {/* <Row className="text-center">
          <Col sm={2}></Col>
          <Col sm={8}>
            <Card className="carddesign" style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title>
                  <div className="text-center">
                    <h3>Our Comprehensive 2 Month Placement Strategy</h3>
                  </div>
                </Card.Title>
                <Card.Text>
                  <div className="desktop">
                    <YouTube
                      className="cusmobilevideo"
                      videoId="SgGZWOF_J-c"
                      opts={opts}
                      onReady={_onReady}
                    />
                  </div>
                  <div className="mobile">
                    <Video src={`https://www.youtube.com/embed/SgGZWOF_J-c`} />
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        {/* <Col sm={6}>
          <div className="mobile"></div>
          <div className="desktop">
            <div style={{ marginTop: 20 }}>
              <img width="100%" src="/assets/img/ghimages/herosvg.svg" />
            </div>
          </div>
        </Col> */}
        <div className="mobile">
          <SliderMenu />
        </div>
        <div className="desktop">
          <SliderMenu />

          {/* 
          change this to rectangle and add in 6 row layout
          <Row className="text-center">
            <div
              className="bannerbg"
              onClick={() => navigate("/jobs", { replace: true })}
            >
              <img
                width="70%"
                src="/assets/img/ghimages/jb1.png"
                alt="job banner"
              />
            </div>
          </Row> */}
        </div>
      </Row>
    </>
  );
};
{
  /* To remove */
}

export default StudentInteractiveDashboard;
