import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import {
  EduCap,
  Mic,
  Promotion,
  Chat,
  OnlineCourse,
  Research,
  Support,
  Books,
  Followers,
  Learners,
  Students,
} from "../../svg";
import YouTube from "react-youtube";
import styled from "styled-components";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const service_data = [
  {
    bg: "blue-bg-4",
    icon: <OnlineCourse />,
    title: (
      <>
        Career oriented <br /> Learning
      </>
    ),
    subtitle: "Arse over tit morish wind up gormless butty.!",
  },
  {
    bg: "pink-bg",
    icon: <Support />,
    title: (
      <>
        Placement
        <br /> Support
      </>
    ),
    subtitle: "Arse over tit morish wind up gormless butty.!",
  },
  {
    bg: "purple-bg",
    icon: <Chat />,
    title: (
      <>
        Industry
        <br /> Connects
      </>
    ),
    subtitle: "Arse over tit morish wind up gormless butty.!",
  },
  {
    bg: "green-bg",
    icon: <Research />,
    title: (
      <>
        Confidence and Life
        <br /> Skills
      </>
    ),
    subtitle: "Arse over tit morish wind up gormless butty.!",
  },
];

const CampusLandingPage = () => {
  const opts = {
    width: "450",
    height: "350",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Campus Landing Page"} />
      <>
        {/* CONTENT START */}
        <div class="page-content">
          {/* EXPLORE NEW LIFE START */}
          <div class="section-full site-bg-light-purple twm-for-employee-4">
            <div class="container">
              <div class="section-content">
                <div class="twm-for-employee-content">
                  <div class="row">
                    <div class="col-xl-5 col-lg-12 col-md-12">
                      <div class="twm-explore-content-outer2">
                        <div class="twm-explore-top-section">
                          {/* TITLE START*/}
                          <div class="section-head left wt-small-separator-outer">
                            <div class="wt-small-separator site-text-primary">
                              <div style={{ fontSize: 36, lineHeight: "36px" }}>
                                Welcoming all Placement Officers!{" "}
                              </div>
                            </div>
                            <h2 style={{ fontSize: 28, marginBottom: 20 }}>
                              Partner with us to greatly improve your placement
                              outcomes!
                            </h2>
                            <p>
                              We focus on outcome-oriented learning where
                              mentors exclusively coach their learners on career
                              strategies and placement skills. This is not about
                              theory, it’s about providing the best interactive
                              hands-on learning that is essential for choosing
                              the right career and landing the perfect
                              opportunity
                            </p>
                          </div>
                          {/* TITLE END*/}
                          <div class="twm-read-more">
                            <Link
                              to="/campus-enquiry-form"
                              className="site-button"
                            >
                              Enroll your College for Free!
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-7 col-lg-12 col-md-12">
                      <div className="hero__thumb d-flex p-relative">
                        <div className="hero__thumb-shape">
                          <img
                            className="hero-1-dot"
                            src="/assets/img/shape/hero/hero-1-dot.png"
                            alt=""
                          />
                          <img
                            className="hero-1-circle-3"
                            src="/assets/img/shape/hero/hero-1-circle-3.png"
                            alt=""
                          />
                          <img
                            className="hero-1-circle-4"
                            src="/assets/img/shape/hero/hero-1-circle-4.png"
                            alt=""
                          />
                        </div>
                        <div className="hero__thumb-big mr-30">
                          {/* <img src={HeroOne.hero_img} alt="" /> */}
                          <div
                            style={{
                              border: "20px solid white",
                              borderRadius: "40px 4px 40px 4px",
                              marginLeft: 40,
                            }}
                            className="desktop"
                          >
                            <YouTube
                              className="cusmobilevideo"
                              videoId="FLrSxW9v9OA"
                              opts={opts}
                              onReady={_onReady}
                            />
                          </div>
                          <div className="mobile">
                            <div
                              style={{
                                marginTop: 20,
                                border: "20px solid white",
                                borderRadius: "40px 4px 40px 4px",
                              }}
                              className="mobile-div-position"
                            >
                              <Video
                                src={`https://www.youtube.com/embed/FLrSxW9v9OA`}
                              />
                            </div>
                          </div>
                          <div className="desktop">
                            <div
                              style={{
                                width: 200,
                                top: "-20px",
                                height: 120,
                                right: -220,
                              }}
                              className="hero__quote hero__quote-animation"
                            >
                              {/*block 1*/}
                              <div
                                style={{ width: 200 }}
                                class="counter-outer-two one anm"
                                data-speed-y="-2"
                                data-speed-scale="15"
                                data-speed-opacity="1"
                              >
                                <div class="icon-content">
                                  <div class="tw-count-number text-clr-yellow-2">
                                    <span class="counter">
                                      <CountUp
                                        style={{
                                          color: "#ffe119",
                                          fontSize: 40,
                                        }}
                                        end={100}
                                      />
                                    </span>
                                    +
                                  </div>
                                  <p class="icon-content-info">Colleges</p>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{ width: 200, bottom: 160, right: -220 }}
                              className="hero__quote hero__quote-animation"
                            >
                              {/*block 2*/}
                              <div
                                class="counter-outer-two two anm"
                                data-speed-y="2"
                                data-speed-scale="15"
                                data-speed-opacity="5"
                              >
                                <div class="icon-content">
                                  <div class="tw-count-number text-clr-green">
                                    <span class="counter">
                                      <CountUp
                                        style={{
                                          color: "#56d8b1",
                                          fontSize: 40,
                                        }}
                                        end={2000}
                                      />
                                    </span>
                                    +
                                  </div>
                                  <p class="icon-content-info">
                                    New job openings
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                bottom: 20,

                                width: 200,
                                right: -220,
                              }}
                              className="hero__quote hero__quote-animation"
                            >
                              {/*block 3*/}
                              <div
                                class="counter-outer-two three anm"
                                data-speed-x="-4"
                                data-speed-scale="-25"
                              >
                                <div class="icon-content">
                                  <div class="tw-count-number text-clr-pink">
                                    <span class="counter text-clr-pink">
                                      <CountUp
                                        style={{
                                          color: "#bc84ca",
                                          fontSize: 40,
                                        }}
                                        end={200}
                                      />
                                    </span>
                                    +
                                  </div>
                                  <p class="icon-content-info">Companies</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* EXPLORE NEW LIFE END */}

          {/* ABOUT SECTION START */}
          <div class="section-full p-t120 site-bg-gray twm-about-1-area">
            <div class="container">
              <div class="twm-about-1-section-wrap">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <div class="twm-about-1-section">
                      <div class="twm-media">
                        <img src="/assets/img/ghjob/about/ab-1.png" alt="" />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-12">
                    <div class="twm-about-1-section-right">
                      {/* TITLE START*/}
                      <div class="section-head left wt-small-separator-outer">
                        <div class="wt-small-separator site-text-primary">
                          <div>Why us? </div>
                        </div>
                        <h3 class="wt-title">
                          {/* Our platform is designed in a way that students are
                          matched against jobs that suit their skillsets. */}
                          Our platform is designed in a way that students become
                          job ready in no time.
                        </h3>
                      </div>
                      {/* TITLE END*/}
                      <ul class="description-list">
                        <li>
                          <i class="feather-check"></i>
                          We provide FREE registration to college students to
                          avail our basic services
                        </li>
                        <li>
                          <i class="feather-check"></i>
                          Our platform provides targeted entry level jobs from
                          various sectors that students can directly apply to
                        </li>
                        <li>
                          <i class="feather-check"></i>
                          We also provide end-to-end services to make students
                          job ready through mentorship programs, interview
                          preparation, live projects and internships
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ABOUT SECTION END */}

          {/* JOB POST START */}
          <div class="section-full site-bg-gray twm-bg-ring-wrap2">
            <div class="container">
              <div class="wt-separator-two-part">
                <div class="row wt-separator-two-part-row">
                  <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                    {/* TITLE START*/}
                    {/* <div class="section-head left wt-small-separator-outer">
                      <div class="wt-small-separator site-text-primary">
                        <div>Featured Jobs</div>
                      </div>
                      <h2 class="wt-title">
                        You can actually invent things here
                      </h2>
                    </div> */}
                    {/* TITLE END*/}
                  </div>
                </div>
              </div>
              {/* Four column layout */}
              <section className="services__area">
                <div className="container">
                  <div className="row">
                    <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3">
                      <div className="section__title-wrapper section-padding mb-60 text-center">
                        {/* <h2 className="section__title">
                          Why{" "}
                          <span className="yellow-bg">
                            Us{" "}
                            <img
                              src="/assets/img/shape/yellow-bg-2.png"
                              alt=""
                            />
                          </span>{" "}
                          ?
                        </h2> */}
                        {/* <p>
                          We focus on job-oriented learning where the mentors
                          exclusively coach their learners on placement skills
                          and strategies. This is not about theory, it’s about
                          providing a structured intervention that is
                          specifically designed to crack placements
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {service_data.map((item, index) => (
                      <div
                        key={index}
                        className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6"
                      >
                        <div className={`services__item ${item.bg} mb-30`}>
                          <div className="services__icon">{item.icon}</div>
                          <div className="services__content">
                            <h3 className="services__title">{item.title}</h3>
                            {/* <p>{item.subtitle}</p> */}
                            <p>&nbsp;</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>

              {/* <div class="section-content">
                <div class="twm-jobs-grid-wrap">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="twm-jobs-featured-style1 m-b30">
                        <div class="">
                          <img src="/assets/img/hero/plan.png" alt="#" />
                        </div>

                        <div class="twm-mid-content">
                          <a href="job-detail.html" class="twm-job-title">
                            <h4>How are these placement programs designed?</h4>
                          </a>
                        </div>
                        <div class="twm-bot-content">
                          <p class="twm-job-address">
                            <i class="feather-map-pin"></i>We customize programs
                            based on the unique needs of Colleges. Programs
                            typically vary from 8 to 24 hours of live
                            interaction broken down into multiple sessions along
                            with AI enabled tools and career navigation
                            solutions
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                      <div
                        style={{ minHeight: "602.6px" }}
                        class="twm-jobs-featured-style1 m-b30"
                      >
                        <div class="">
                          <img src="/assets/img/hero/60.png" alt="#" />
                        </div>

                        <div class="twm-mid-content">
                          <a href="job-detail.html" class="twm-job-title">
                            <h4>How do we reward our partner colleges?</h4>
                          </a>
                        </div>
                        <div class="twm-bot-content">
                          <p class="twm-job-address">
                            <i class="feather-map-pin"></i>We organize speaker
                            sessions, industry interaction, career workshops and
                            a host of other value-added services for our
                            esteemed Partner Colleges.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* JOB POST END */}
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* CONTENT END */}
      </>
    </Wrapper>
  );
};

export default CampusLandingPage;
