import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

import Brands from "../../components/common/brand/brands";
import TestimonialThree from "../../components/common/testimonials/testimonial-3";
import YouTube from "react-youtube";
import styled from "styled-components";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const About = () => {
  const opts = {
    width: "645",
    height: "365",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <>
      <Wrapper>
        <SEO pageTitle={"About Us"} />
        <div className="row">
          <div className="col-md-6">
            <div style={{ marginTop: 70 }} className="desktop text-center">
              <YouTube
                className="cusmobilevideo"
                videoId="Ze1jM-rqgak"
                opts={opts}
                onReady={_onReady}
              />
            </div>
            <div className="mobile">
              <Video src={`https://www.youtube.com/embed/Ze1jM-rqgak`} />
            </div>
          </div>
          <div style={{ padding: 50, marginTop: 25 }} className="col-md-6">
            <h2>Who Are We?</h2>
            <p style={{ paddingTop: 20 }}>
              Achillobator is a <b>one-stop solution</b> that provides
              employability and life skills to the youth of India. Our aim is to
              equip every youth in India with the right tools, skills and
              mindset to succeed in any competitive career.
            </p>
          </div>
        </div>
        <div className="row">
          <div style={{ padding: 50 }} className="col-md-6">
            <h2>The Employability Challenge</h2>
            <p style={{ paddingTop: 20 }}>
              The starting point of any successful career is knowing what skills
              we have and how we can use them. But simply knowing is not enough.
              We need to develop ourselves through personalized coaching so that
              when we face interviews, we can give it our best. Starting with
              &nbsp;<b>building your profile</b> to <b>writing your resume</b>{" "}
              to preparing for <b>aptitude assessments</b>, everything can be a
              daunting task. When you add <b>group discussions</b> and{" "}
              <b>interviews</b> to this list, it quickly becomes nerve-wrecking.
            </p>
            <p style={{ paddingTop: 20 }}>
              Academic institutions prepare us well on technical knowledge;
              however, when it comes to building your profile to crack career
              opportunities, most of us struggle!
            </p>
          </div>
          <div className="col-md-6">
            <div style={{ marginTop: 70 }} className="desktop">
              <YouTube
                className="cusmobilevideo"
                videoId="VbtspjcyjsI"
                opts={opts}
                onReady={_onReady}
              />
            </div>
            <div className="mobile">
              <Video src={`https://www.youtube.com/embed/VbtspjcyjsI`} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div style={{ marginTop: 70 }} className="desktop text-center">
              <YouTube
                className="cusmobilevideo"
                videoId="L6MlSveKvc0"
                opts={opts}
                onReady={_onReady}
              />
            </div>
            <div className="mobile">
              <Video src={`https://www.youtube.com/embed/L6MlSveKvc0`} />
            </div>
          </div>
          <div style={{ padding: 50 }} className="col-md-6">
            <h2>How does Achillobator help?</h2>
            <p style={{ paddingTop: 20 }}>
              Through our experienced industry mentors and smart AI Tools, we
              provide each student the three most important aspects for
              achieving an outstanding career:
            </p>
            <p>* Build a strong profile</p>
            <ul>
              <li>
                <b>
                  {" "}
                  <i class="fa fa-circle" aria-hidden="true"></i>
                </b>
              </li>
              <li>
                <b>
                  <i class="fa fa-circle" aria-hidden="true"></i>Upskill to
                  become interview-ready
                </b>{" "}
              </li>
              <li>
                <b>
                  <i class="fa fa-circle" aria-hidden="true"></i>Find the right
                  job with the click of a button
                </b>
              </li>
            </ul>
            <p style={{ paddingTop: 20 }}>
              We want to keep things simple. Hence we focus on those critical
              aspects including your communication, confidence, and behavioral
              skills that often create roadblocks for you. It doesn’t matter if
              you are a student, a college graduate, or a working professional –
              we are here to bridge your gap and make you <b>Career Ready!</b>
            </p>
          </div>
        </div>

        {/* brands start */}
        <Brands />
        {/* brands end */}

        {/* testimonial start */}
        <TestimonialThree />
        {/* testimonial end */}
      </Wrapper>
    </>
  );
};

export default About;
