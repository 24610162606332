import React from "react";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import styled from "styled-components";
const about_info = {
  reviews: "8,200+",
  about_img: "/assets/img/about/about.jpg",
  about_banner: "/assets/img/about/about-banner.jpg",
  students_imgs: [
    "/assets/img/about/student-4.jpg",
    "/assets/img/about/student-3.jpg",
    "/assets/img/about/student-2.jpg",
    "/assets/img/about/student-1.jpg",
  ],
  students: "",
  title: <>{/* Achieve your <br /> */}</>,
  shape_text: "",
  title_2: "About me",
  desc: "An entrepreneur, personal-growth coach, motivational speaker, artist, and unshakable optimist. Born and raised in Mumbai with nothing more than a passion and dream to create a better world for the future generation. I started my Training & Coaching practice very early, with a sole mission & dedication to help as many people as I could in procuring their dreams and backing it up with meaningful action to create successful results.",
  about_list: [
    "Career Counselling.",
    "Interview Preparation",
    "Soft Skills Building and Placement Readiness",
  ],
};
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const AboutArea = ({ padd = "pt-90 pb-90" }) => {
  const opts = {
    width: "645",
    height: "365",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  return (
    <>
      <section style={{ paddingBottom: 50 }} className={`about__area ${padd}`}>
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <img
                style={{ borderRadius: "50%" }}
                src="/assets/img/ghimages/sushma.png"
              />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6">
              <div className="about__content pl-70 pr-60 pt-25">
                <div className="section__title-wrapper mb-25">
                  <h2 style={{ fontSize: 36 }} className="section__title">
                    {about_info.title}
                    <span className="yellow-bg-big">
                      {about_info.shape_text}{" "}
                      <img src="/assets/img/shape/yellow-bg-2.png" alt="" />
                    </span>{" "}
                    {about_info.title_2}{" "}
                  </h2>
                  <p>{about_info.desc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutArea;
