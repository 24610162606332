import axios from "axios";
import config from "./config";
import { getDataFromLocalStorage } from "../browser-storage";
import { LocalStorageConstants } from "./localstorageconstants";

const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN);

const login = async (email: string, password: string): Promise<any> => {
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/login`, {
      userName: email,
      password: password,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const forgetPassword = async (email: string): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/forgot-password`, {
      userName: email,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const updatePassword = async (
  email: string,
  token: string,
  new_password: string
): Promise<any> => {
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/forgot-password`, {
      userName: email,
      newPassword: new_password,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const resetPasswordbyAdmin = async (details: string): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .put(
      `${config.PROTOCOL}${config.HOST}/user/reset-password-by-admin`,

      details,
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const verifyTokenService = async (
  email: string,
  token: string
): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/verify-token`, {
      userName: email,
      token: token,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const emailActivation = async (email: string, token: string): Promise<any> => {
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/email-activation`, {
      userName: email,
      token: token,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const emailActivationLink = async (email: string): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/email-activation-link`, {
      userName: email,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const welcomePlanEmail = async (email: string): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/welcome-plan-email`, {
      userName: email,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const resetPassword = async (): Promise<any> => {
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/user/reset-password/:id/:token`)
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const logout = async (): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/logout`, {}, { headers })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const getUserProfile = async (): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/user/profile`, { headers })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const getAllUserScore = async (email: string): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/user/get-all-score-by-emailId`,
      {
        username: email,
      },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const signup = async (email: string, password: string): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/signup`, {
      userName: email,
      password: password,
      email: email,
    })
    .then(({ status, data }) =>
      status === 201 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const signupv2 = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  mobile: string
): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/signup-v2`, {
      userName: email,
      password: password,
      email: email,
      firstName: firstName,
      lastName: lastName,
      mobile: mobile,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const recuitersignup = async (
  email: string,
  password: string,
  mobile: string,
  firstName: string,
  lastName: string
): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/employer-signup`, {
      userName: email,
      password: password,
      email: email,
      mobile: mobile,
      firstName: firstName,
      lastName: lastName,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const Mentorsignup = async (
  email: string,
  password: string,
  mobile: string,
  firstName: string,
  lastName: string
): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/mentor-signup`, {
      userName: email,
      password: password,
      email: email,
      mobile: mobile,
      firstName: firstName,
      lastName: lastName,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const Campussignup = async (
  email: string,
  password: string,
  mobile: string,
  firstName: string,
  lastName: string
): Promise<any> => {
  return axios
    .post(`${config.PROTOCOL}${config.HOST}/user/campus-signup`, {
      userName: email,
      password: password,
      email: email,
      mobile: mobile,
      firstName: firstName,
      lastName: lastName,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const Adminsignup = async (
  email: string,
  password: string,
  mobile: string,
  firstName: string,
  lastName: string
): Promise<any> => {
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/user/secret-link-admin-signup-xx-yy-00`,
      {
        userName: email,
        password: password,
        email: email,
        mobile: mobile,
        firstName: firstName,
        lastName: lastName,
      }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const profileSetup = async ({
  firstName,
  lastName,
  mobile,
  dob,
  collegecode,
  instituteName,
  degree,
  graduationYear,
}: {
  firstName: string;
  lastName: string;
  mobile: string;
  dob: number;
  collegecode: string;
  instituteName: string;
  degree: string;
  graduationYear: string;
}): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/user/profile`,
      {
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        dob: dob,
        collegecode: collegecode,
        instituteName: instituteName,
        degree: degree,
        graduationYear: graduationYear,
      },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const updateUserProfile = async (data: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/profile`, data, { headers })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const addProfilePic = async (img: File): Promise<any> => {
  const formData = new FormData();
  formData.append("profilePic", img);
  const headers = {
    Authorization: `Bearer ${getToken()}`,
    "content-type": "multipart/form-data",
  };
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/profile-pic`, formData, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const addResumeProfilePic = async (img: File): Promise<any> => {
  const formData = new FormData();
  formData.append("profilePic", img);
  const headers = {
    Authorization: `Bearer ${getToken()}`,
    "content-type": "multipart/form-data",
  };
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/resume-profile-pic`, formData, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const addMentorProfilePic = async (img: File): Promise<any> => {
  const formData = new FormData();
  formData.append("profilePic", img);
  const headers = {
    Authorization: `Bearer ${getToken()}`,
    "content-type": "multipart/form-data",
  };
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/mentor-profile-pic`, formData, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const addCompanyProfilePic = async (img: File): Promise<any> => {
  const formData = new FormData();
  formData.append("profilePic", img);
  const headers = {
    Authorization: `Bearer ${getToken()}`,
    "content-type": "multipart/form-data",
  };
  return axios
    .put(
      `${config.PROTOCOL}${config.HOST}/user/company-profile-pic`,
      formData,
      {
        headers,
      }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const addCoverPic = async (img: File): Promise<any> => {
  const formData = new FormData();
  formData.append("coverPic", img);
  const headers = {
    Authorization: `Bearer ${getToken()}`,
    "content-type": "multipart/form-data",
  };
  return axios
    .put(`${config.PROTOCOL}${config.HOST}/user/profile-cover-pic`, formData, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const SendMobileNotification = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/user/send-mobile-notification`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const AddStudentEnquiryForm = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/enquiry/add-student-form`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const AddStudentToMentorEnquiryForm = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/enquiry/add-student-to-mentor-form`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const AddStudentEnquiryFormWithResume = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/enquiry/add-student-form-with-resume`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const GetAllStudentEntries = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/enquiry/get-student-entries`, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const AddCampusAmbassadorForm = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/enquiry/add-campus-am-form`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const GetCampusAmbassadorEntries = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(
      `${config.PROTOCOL}${config.HOST}/enquiry/get-campus-ambassador-entries`,
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const AddCampusPlacementForm = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/enquiry/add-campus-placement-form`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const GetCampusPlacementEntries = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(
      `${config.PROTOCOL}${config.HOST}/enquiry/get-campus-placement-entries`,
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const AddCompanyForm = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/enquiry/add-companies-form`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const GetCompanyEntries = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/enquiry/get-companies-entries`, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const AddMentorForm = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/enquiry/add-mentor-form`,
      { payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const GetMentorEntries = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(`${config.PROTOCOL}${config.HOST}/enquiry/get-mentor-entries`, {
      headers,
    })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

export {
  getAllUserScore,
  resetPasswordbyAdmin,
  login,
  addCompanyProfilePic,
  addMentorProfilePic,
  AddStudentToMentorEnquiryForm,
  AddStudentEnquiryFormWithResume,
  Mentorsignup,
  recuitersignup,
  Adminsignup,
  Campussignup,
  GetMentorEntries,
  AddMentorForm,
  GetCompanyEntries,
  GetCampusPlacementEntries,
  AddCampusPlacementForm,
  AddCompanyForm,
  AddCampusAmbassadorForm,
  GetCampusAmbassadorEntries,
  SendMobileNotification,
  AddStudentEnquiryForm,
  GetAllStudentEntries,
  verifyTokenService,
  emailActivationLink,
  welcomePlanEmail,
  forgetPassword,
  updatePassword,
  resetPassword,
  emailActivation,
  logout,
  getUserProfile,
  updateUserProfile,
  addResumeProfilePic,
  signup,
  signupv2,
  profileSetup,
  addProfilePic,
  addCoverPic,
};
