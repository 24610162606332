import axios from "axios";
import config from "./config";
import { getDataFromLocalStorage } from "../browser-storage";
import { LocalStorageConstants } from "./localstorageconstants";

const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN);

const quickInterviewToken = (): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .get(
      `${config.PROTOCOL}${config.HOST}/interview/quick-interview-sso-token`,
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const obtainCognicueToken = (): Promise<any> => {
  const headers = {
    Authorization: "Token 4a4dc12a68044c94e6bd351a707de2efb3301692",
  };
  return axios
    .post(`https://services.cognicue.in/api/token/obtain/`, { headers })
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const cognicuecandidateinterview = (res: any): Promise<any> => {
  const headers = {
    accept: "application/json",
    Authorization: "Token 4a4dc12a68044c94e6bd351a707de2efb3301692",
  };
  return axios
    .get(
      `https://services.cognicue.in/api/account/candidate-interview/` + res,
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const quickInterview = (token: string): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .put(
      `${config.PROTOCOL}${config.HOST}/interview/quick-interview`,
      { token: token },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};
const quickCandidateInterview = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/interview/quick-candidate-interview`,
      { payload: payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

const getQuickInterviewDetailsbyId = (payload: any): Promise<any> => {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(
      `${config.PROTOCOL}${config.HOST}/interview/get-ai-interview-by-emailid`,
      { payload: payload },
      { headers }
    )
    .then(({ status, data }) =>
      status === 200 && data ? Promise.resolve(data) : Promise.reject()
    )
    .catch((err) => Promise.reject(err));
};

export {
  quickInterviewToken,
  quickInterview,
  obtainCognicueToken,
  quickCandidateInterview,
  cognicuecandidateinterview,
  getQuickInterviewDetailsbyId,
};
