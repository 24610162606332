import React from "react";
import JobsPopupLoginForm from "../../pages/jobs/jobs-popup-login-form";
import Modal from "react-modal";
const PackageItem = ({
  selectedPackagePrice,
  discountValue,
  discountValueToShow,
  isLoggedInStatus,
  modalIsOpen,
  afterOpenModal,
  closeModal,
  customStyles,
  displayRazorpay,
  openModal,
  coupondetails,
  couponDetailsonChange,
  checkIsCouponValidfunction,
  packageDetails,
}) => {
  const { title, price, features, customizationText } = packageDetails;

  return (
    selectedPackagePrice === price && (
      <div
        className="price__item grey-bg mb-30 p-relative"
        style={{ padding: "20px" }}
      >
        <div className="price__head">
          <h3>{title}</h3>
        </div>
        {!discountValue ? (
          <div className="price__tag mb-25">
            <h4>
              ₹ {price}
              <span style={{ fontSize: 20 }}>
                &nbsp;(+ 2.7% Online Transaction fee)
              </span>
            </h4>
          </div>
        ) : (
          <div className="price__tag mb-25">
            <div className="style-1">
              <del>
                <span className="amount">₹ {price}</span>
              </del>
              <ins>
                <span className="amount">₹ {discountValueToShow}</span>
              </ins>
              <br />
              <span style={{ fontSize: 16 }}>
                &nbsp;(+ 2.7% Online Transaction fee)
              </span>
            </div>
          </div>
        )}

        <div className="price__features mb-40">
          <ul>
            {features.map((feature, index) => (
              <li key={index}>
                <i className="far fa-check"></i>
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <p>* {customizationText}</p>

        {isLoggedInStatus === true ? (
          <>
            <div className="text-center">
              <button
                className="e-btn e-btn-border"
                style={{ marginTop: "5px" }}
                onClick={displayRazorpay}
              >
                Book Now
              </button>
            </div>
            <p>
              <span style={{ color: "black", fontSize: 18 }}>
                Final Price :{" "}
              </span>
              <span style={{ color: "green", fontSize: 18 }}>
                {discountValue}
              </span>{" "}
              (<span style={{ color: "green" }}>{discountValueToShow}</span>+
              2.7% Transcation fee)
            </p>
          </>
        ) : null}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <JobsPopupLoginForm />
        </Modal>
        {isLoggedInStatus === false ? (
          <>
            <div className="text-center">
              <button
                className="e-btn e-btn-border"
                style={{ marginTop: "5px" }}
                onClick={openModal}
              >
                Login
              </button>
            </div>
            <p style={{ color: "red" }}>* Please Login to Complete Payment</p>
          </>
        ) : null}
        <p>Have a Coupon code ?</p>

        {isLoggedInStatus === true ? (
          <>
            <input
              placeholder="Coupon Code"
              className="form-control marginTop5"
              type="text"
              name="coupon_code_submitted"
              value={coupondetails.coupon_code_submitted}
              onChange={couponDetailsonChange}
            />

            <button
              className="e-btn e-btn-border"
              style={{ marginTop: "5px" }}
              onClick={checkIsCouponValidfunction}
            >
              Apply Now
            </button>
          </>
        ) : (
          <>
            {" "}
            <input
              placeholder="Coupon Code"
              className="form-control marginTop5"
              type="text"
              name="coupon_code_submitted"
              value={coupondetails.coupon_code_submitted}
              onChange={couponDetailsonChange}
              disabled
            />
            <p style={{ color: "red" }}>* Please Login to apply coupons</p>
            <button
              className="e-btn e-btn-border"
              style={{ marginTop: "5px" }}
              disabled
            >
              Apply Now
            </button>
          </>
        )}
      </div>
    )
  );
};

export default PackageItem;
