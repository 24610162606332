import React from 'react';

const Grid = () => {
  return (
    <svg className="grid" viewBox="0 0 24 24">
      <rect x="3" y="3" className="st0" width="7" height="7" />
      <rect x="14" y="3" className="st0" width="7" height="7" />
      <rect x="14" y="14" className="st0" width="7" height="7" />
      <rect x="3" y="14" className="st0" width="7" height="7" />
    </svg>
  );
};

export default Grid;