const student_menu_data = [
  {
    id: 0,
    title: "Dashboard",
    link: "/student-dashboard",
  },
  // {
  //   id: 1,
  //   title: "Career Tools",
  //   link: "/career-tools",
  // },
  // {
  //   id: 2,
  //   title: "Mentors",
  //   link: "/mentors",
  // },
  // {
  //   id: 3,
  //   title: "Jobs",
  //   link: "/jobs",
  // },

  // {
  //   id: 4,
  //   title: "For Campus",
  //   link: "/campus",
  // },
  // {
  //   id: 5,
  //   title: "About Us",
  //   link: "/about",
  // },
];

export default student_menu_data;
