import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import { RootState } from "../../../redux/oldplatformreducer/oldCombineReducer";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import EditIntroModal from "./edit-intro-modal";
import AddEditBgPhotoModal from "./add-edit-bg-photo-modal";
import AddEditProfilePicModal from "./add-edit-profile-pic-modal";
import BootContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./profile.css";
import { getUserProfile } from "../../../services/user-service";
import config from "../../../services/config";
import {
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from "../../../redux/features/profile-slice";
import { store } from "../../../redux/store";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Cover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 264px;
  padding: 23px;
  background: ${({ coverImg }) =>
    coverImg ? `url("${coverImg}")` : "#c4c4c4"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    cursor: pointer;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 49px;
  width: 100%;
  height: 148px;
  background: #ffffff;
  padding: 9px 27px;
`;

const ProfilePic = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 232px;
  height: 232px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: ${({ profileImg }) =>
    profileImg ? `url("${profileImg}")` : "#c4c4c4"};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -116px;
  padding: 15px;
  img {
    cursor: pointer;
  }
`;
const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  div:nth-child(1) {
    font-family: Poppins-Bold;
    font-size: 28px;
    color: #016738;
    img {
      margin-left: 25px;
      cursor: pointer;
    }
  }
  div:nth-child(2) {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: rgba(28, 26, 25, 0.6);
  }
  div:nth-child(3) {
    font-family: Poppins-Regular;
    font-size: 16px;
    color: rgba(28, 26, 25, 0.48);
  }
`;

const Intro = () => {
  // Infer the `RootState` and `AppDispatch` types from the store itself
  //export type RootState = ReturnType<typeof store.getState>;
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  //export type AppDispatch = typeof store.dispatch;

  const dispatch = useDispatch();
  let getProfileDetails = async () => {
    const ProfileResponse = await getUserProfile()
      .then((res) => {
        console.log("res", res);
        dispatch(GET_USER_PROFILE(res));
        dispatch(UPDATE_USER_PROFILE(res));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const firstName = useSelector((state) => state.profile.firstName);
  const lastName = useSelector((state) => state.profile.lastName);
  const profession = useSelector((state) => state.profile.profession);
  const location = useSelector((state) => state.profile.location);
  const profileImgUrl = useSelector((state) => state.profile.profileImgUrl);
  const coverImgUrl = useSelector((state) => state.profile.coverImgUrl);
  const [isEditIntroModalOpen, setIsEditIntroModalOpen] = useState(false);
  const [isAddBgPhotoModalOpen, setIsAddBgPhotoModalOpen] = useState(false);
  const [isAddProfilePicModalOpen, setIsAddProfilePicModalOpen] =
    useState(false);
  return (
    <>
      <div className="desktop">
        <BootContainer className="InfoProfileContainer">
          <Cover coverImg={coverImgUrl}>
            <img
              src={
                coverImgUrl
                  ? "/assets/img/ghimages/edit-icon.svg"
                  : "/assets/img/ghimages/add-photo-icon.svg"
              }
              alt="+"
              onClick={() => setIsAddBgPhotoModalOpen(true)}
            />
          </Cover>
          <InfoContainer>
            <ProfilePic profileImg={profileImgUrl}>
              <img
                src={
                  profileImgUrl
                    ? "/assets/img/ghimages/edit-icon.svg"
                    : "/assets/img/ghimages/add-photo-icon.svg"
                }
                alt="+"
                onClick={() => setIsAddProfilePicModalOpen(true)}
              />
            </ProfilePic>
            <Card className="profileCard">
              <InfoTextContainer>
                <div>
                  {firstName || ""} {lastName || ""}
                  <img
                    src={"/assets/img/ghimages/edit-icon.svg"}
                    alt="-"
                    onClick={() =>
                      setIsEditIntroModalOpen((prevState) => !prevState)
                    }
                  />
                </div>
                <div>{profession || ""}</div>
                <div>{location || ""}</div>
              </InfoTextContainer>
            </Card>
          </InfoContainer>
        </BootContainer>
        {isEditIntroModalOpen && (
          <EditIntroModal closeAction={() => setIsEditIntroModalOpen(false)} />
        )}
        {isAddBgPhotoModalOpen && (
          <AddEditBgPhotoModal
            closeAction={() => setIsAddBgPhotoModalOpen(false)}
            forEdit={!!coverImgUrl}
          />
        )}
        {isAddProfilePicModalOpen && (
          <AddEditProfilePicModal
            closeAction={() => setIsAddProfilePicModalOpen(false)}
            forEdit={!!profileImgUrl}
          />
        )}
      </div>
      <div className="mobile">
        <BootContainer className="InfoProfileContainer">
          <Row>
            <Col></Col>
          </Row>
          <Cover coverImg={coverImgUrl}>
            <img
              src={
                coverImgUrl
                  ? "/assets/img/ghimages/edit-icon.svg"
                  : "/assets/img/ghimages/add-photo-icon.svg"
              }
              alt="+"
              onClick={() => setIsAddBgPhotoModalOpen(true)}
            />
          </Cover>
          <InfoContainer>
            <ProfilePic profileImg={profileImgUrl}>
              <img
                src={
                  profileImgUrl
                    ? "/assets/img/ghimages/edit-icon.svg"
                    : "/assets/img/ghimages/add-photo-icon.svg"
                }
                alt="+"
                onClick={() => setIsAddProfilePicModalOpen(true)}
              />
            </ProfilePic>
          </InfoContainer>
          <BootContainer>
            <Row>
              <Card>
                <InfoTextContainer>
                  <div>
                    {firstName || ""} {lastName || ""}
                    <img
                      src={"/assets/img/ghimages/edit-icon.svg"}
                      alt="-"
                      onClick={() =>
                        setIsEditIntroModalOpen((prevState) => !prevState)
                      }
                    />
                  </div>
                  <div>{profession || ""}</div>
                  <div>{location || ""}</div>
                </InfoTextContainer>
              </Card>
            </Row>
          </BootContainer>
        </BootContainer>
        {isEditIntroModalOpen && (
          <EditIntroModal closeAction={() => setIsEditIntroModalOpen(false)} />
        )}
        {isAddBgPhotoModalOpen && (
          <AddEditBgPhotoModal
            closeAction={() => setIsAddBgPhotoModalOpen(false)}
            forEdit={!!coverImgUrl}
          />
        )}
        {isAddProfilePicModalOpen && (
          <AddEditProfilePicModal
            closeAction={() => setIsAddProfilePicModalOpen(false)}
            forEdit={!!profileImgUrl}
          />
        )}
      </div>
    </>
  );
};

export default Intro;
