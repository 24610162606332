import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import YouTube from "react-youtube";

import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";

import React, { useEffect, useState } from "react";

import "./homepaymentpage.css";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;

const ReviewVideoGallery = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
    },
  };
  let subtitle;

  const [currentSource, setCurrentSource] = useState();

  const opts = {
    width: "100%",
    height: "265",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    videoCode: "FLrSxW9v9OA",
  });
  const setSeletedvideotoplay = (item) => {
    console.log("item", item);
    if (item == "Student1") {
      setCurrentvideotoplay({
        videoCode: "FLrSxW9v9OA",
      });
    }
    if (item == "Student2") {
      setCurrentvideotoplay({
        videoCode: "QqDya24v0R4",
      });
    }
    if (item == "GHIntro") {
      setCurrentvideotoplay({
        videoCode: "HQLEWpibxUY",
      });
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <div className="section__title-wrapper mb-45"></div>
          <div className="col-md-9">
            <div className="text-center">
              <div className="desktop">
                <YouTube
                  className="cusmobilevideo"
                  videoId={currentvidetoplay.videoCode}
                  opts={opts}
                  onReady={_onReady}
                />
              </div>
              <div className="mobile">
                <Video src={`https://www.youtube.com/embed/QqDya24v0R4`} />
              </div>
              <div className="container">
                <div className="row">
                  <div
                    style={{ paddingLeft: 2, paddingRight: 2 }}
                    className="col-md-4"
                  >
                    <img
                      onClick={() => setSeletedvideotoplay("Student1")}
                      src="/assets/img/ghimages/studentreviewimg.png"
                    />
                  </div>
                  <div
                    style={{ paddingLeft: 2, paddingRight: 2 }}
                    className="col-md-4"
                  >
                    <img
                      onClick={() => setSeletedvideotoplay("Student2")}
                      src="/assets/img/ghimages/studentreviewing2.png"
                    />
                  </div>
                  <div
                    style={{ paddingLeft: 2, paddingRight: 2 }}
                    className="col-md-4"
                  >
                    <img
                      onClick={() => setSeletedvideotoplay("GHIntro")}
                      src="/assets/img/ghimages/platformintro.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReviewVideoGallery;
