import axios from 'axios'
import config from './config'
import { getDataFromLocalStorage } from '../browser-storage'
import { LocalStorageConstants } from './localstorageconstants'

const getToken = () => getDataFromLocalStorage(LocalStorageConstants.TOKEN)


const getCourseListing = (): Promise<any> => {
    const headers = {
      Authorization: `Bearer ${getToken()}`,
    }
    return axios
      .get(`${config.PROTOCOL}${config.HOST}/course/get-course-listing`, { headers })
      .then(({ status, data }) => (status === 200 && data ? Promise.resolve(data) : Promise.reject()))
      .catch((err) => Promise.reject(err))
  }
  export{getCourseListing}