import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { menu_bar, selectMenuBar } from "../../redux/features/header-slice";
import { Search } from "../../svg";

const Sidebar = () => {
  const menuOpen = useSelector(selectMenuBar);
  const dispatch = useDispatch();
  const [home, setHome] = useState(false);
  const [courses, setcourses] = useState(false);
  const [blog, setBlog] = useState(false);
  const [pages, setPages] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "home") {
      setHome(!home);
      setcourses(false);
      setBlog(false);
      setPages(false);
    } else if (menu === "courses") {
      setHome(false);
      setcourses(!courses);
      setBlog(false);
      setPages(false);
    } else if (menu === "blog") {
      setHome(false);
      setcourses(false);
      setBlog(!blog);
      setPages(false);
    } else if (menu === "pages") {
      setHome(false);
      setcourses(false);
      setBlog(false);
      setPages(!pages);
    }
  };

  return (
    <>
      <div className={menuOpen ? "sidebar__area open" : "sidebar__area"}>
        <div className="sidebar__wrapper">
          <div className="sidebar__close">
            <button
              className="sidebar__close-btn"
              id="sidebar__close-btn"
              onClick={() => dispatch(menu_bar(false))}
            >
              <span>
                <i className="far fa-times"></i>
              </span>
              <span>close</span>
            </button>
          </div>
          <div className="sidebar__content">
            <div className="logo mb-40">
              <Link to="/">
                <img src="/assets/img/logo/GH1.png" alt="logo" />
              </Link>
            </div>
            <div className="mm-menu">
              <ul>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/">Home </Link>
                </li>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/about">About Us </Link>
                </li>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/mentors">Our Mentors </Link>
                </li>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/career-tools">Career Tools </Link>
                </li>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/become-mentor">For Mentors </Link>
                </li>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/recuiter">For Recruiters </Link>
                </li>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/campus">For Colleges </Link>
                </li>

                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/jobs">Jobs </Link>
                </li>
                <li onClick={() => dispatch(menu_bar(false))}>
                  <Link to="/student-login">Sign in</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- sidebar overlay end --> */}
      <div
        onClick={() => dispatch(menu_bar(false))}
        className={`body-overlay ${menuOpen ? "opened" : ""}`}
      ></div>
      {/* <!-- sidebar overlay end --> */}
    </>
  );
};

export default Sidebar;
