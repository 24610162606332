export const testimonial_1 = [
  {
    id: 1,
    img: "/assets/img/testimonial/testi-1.jpg",
    content:
      "“ Barmy loo sloshed porkiesdo with me down the pub say bubble and squeak. ”",
    name: "Jason Response",
    title: "UX Designer",
  },
  {
    id: 2,
    img: "/assets/img/testimonial/testi-1.jpg",
    content:
      "“ Barmy loo sloshed porkiesdo with me down the pub say bubble and squeak. ”",
    name: "Naim Ahmed",
    title: "Web developer",
  },
];

export const testimonial_2 = [
  {
    id: 1,
    desc: "“During my one month coaching, I learnt how to control my anxiety and speak in front of people with confidence. I also learnt how to answer tough interview questions successfully by using simple techniques. The assessments and the coaching modules are just perfect.”",
    name: "Kamika, ",
    subtitle: " Student @Achillobator",
  },
];

export const testimonial_2_nav = [
  {
    img: "/assets/img/testimonial/home-3/testi-1.jpg",
  },
  {
    img: "/assets/img/testimonial/home-3/testi-2.jpg",
  },
  {
    img: "/assets/img/testimonial/home-3/testi-3.jpg",
  },
  {
    img: "/assets/img/testimonial/home-3/testi-2.jpg",
  },
];
