import React from 'react';

const Event = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style={{enableBackground:'new 0 0 16 16'}} xmlSpace="preserve">
      <path className="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z" />
      <polyline className="st0" points="6,14.7 6,8 10,8 10,14.7 " />
    </svg>
  );
};

export default Event;