export const HeroOne = {
  // sm_text: "Access 2000 +",
  sm_text: "",
  shape_text: "Job",
  title:
    " Openings every week and get coached by top industry mentors to land your dream career.",
  subtitle:
    "Meet university,and cultural institutions, who'll share their experience.",
  btn_text: "Enquire Now",
  hero_img: "/assets/img/hero/63.png",
  hero_sm_img: "",
  hero__quote: "Are you ready to",
  hero__quote_2: "launch your career?",
};

export const hero_two = {
  title: (
    <>
      Launch your <br /> Own
    </>
  ),
  img: "/assets/img/hero/24nc.png",
  shape_text: "online",
  title_2: "learning Platform",
  subtitle: "Unlimited access to all 60+ instructors.",
  sm_text: "2 passes (with access to all classes) for $240",
  hero_thumb_title: "Congratulations",
  hero_thumb_text: "You are ready for your Interview",
  hero_thumb_number: 200,
  hero_thumb_text_2: "Colleges",
  hero_thumb_title_2: "GreenHorn Score",
  hero_thumb_text_3: "Your Score is 90. Awesome!",
};

export const hero_slider_data = [
  {
    bg_img: "/assets/img/slider/bg2.jpg",
    sm_title: "",
    title_b_s: "How to grab your  ",
    shape_text: "8+ lakhs",
    title_a_s: "dream job through our training services",
    text: "",
    btn_text: "Ready to get Started?",
  },
  // {
  //   bg_img: "/assets/img/slider/tech_demo.png",
  //   sm_title: "Learn & Achieve",
  //   title_b_s: "Fundamentals of music",
  //   shape_text: "theory",
  //   title_a_s: "Learn",
  //   text: "Meet university,and cultural institutions, who'll share their experience.",
  //   btn_text: "Ready to get Started?",
  // },
  // {
  //   bg_img: "/assets/img/slider/slider-2.jpg",
  //   sm_title: "Learn & Achieve",
  //   title_b_s: "Become a product",
  //   shape_text: "Manager",
  //   title_a_s: "& Learn.",
  //   text: "Meet university,and cultural institutions, who'll share their experience.",
  //   btn_text: "Ready to get Started?",
  // },
  // {
  //   bg_img: "/assets/img/slider/slider-3.jpg",
  //   sm_title: "Learn & Achieve",
  //   title_b_s: "Launch your",
  //   shape_text: "Online",
  //   title_a_s: "Learning Platform.",
  //   text: "Meet university,and cultural institutions, who'll share their experience.",
  //   btn_text: "Ready to get Started?",
  // },
];

export const hero_slider_nav = [
  {
    id: 1,
    img: "/assets/img/slider/nav/slider-nav-4.jpg",
    title: "6 Courses",
    subtitle: "Programming Languages",
    bgColor: "orange-bg",
  },
  {
    id: 2,
    img: "/assets/img/slider/nav/slider-nav-1.jpg",
    title: "4 Courses",
    subtitle: "Idea Discussion",
    bgColor: "blue-bg",
  },
  {
    id: 3,
    img: "/assets/img/slider/nav/slider-nav-2.jpg",
    title: "8 Courses",
    subtitle: "Web Development",
    bgColor: "pink-bg",
  },
  {
    id: 4,
    img: "/assets/img/slider/nav/slider-nav-3.jpg",
    title: "9 Courses",
    subtitle: "System Administration",
    bgColor: "green-bg",
  },
];
