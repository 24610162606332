import React from 'react';

const List = () => {
  return (
    <svg className="list" viewBox="0 0 512 512">
      <g id="Layer_2_1_">
        <path className="st0" d="M448,69H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,69,448,69z" />
        <circle className="st0" cx="64" cy="100" r="31" />
        <path className="st0" d="M448,225H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,225,448,225z" />
        <circle className="st0" cx="64" cy="256" r="31" />
        <path className="st0" d="M448,381H192c-17.7,0-32,13.9-32,31s14.3,31,32,31h256c17.7,0,32-13.9,32-31S465.7,381,448,381z" />
        <circle className="st0" cx="64" cy="412" r="31" />
      </g>
    </svg>
  );
};

export default List;