import React from 'react';

const Enrolled = () => {
  return (
    <svg>
      <path className="st0" d="M13.3,14v-1.3c0-1.5-1.2-2.7-2.7-2.7H5.3c-1.5,0-2.7,1.2-2.7,2.7V14" />
      <circle className="st0" cx="8" cy="4.7" r="2.7" />
    </svg>
  );
};

export default Enrolled;