import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs, Controller } from "swiper";

import { testimonial_2, testimonial_2_nav } from "../../../data";

const BulletList = [
  "Psychometric Test",
  "Focused group Intervention on Resume, GD, PI",
  "Post training Practice journey",
  "Emerging technology training",
  "Mentoring Sessions by Industry Experts (Offline/ Online)",
];

const TestimonialThree = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <section
        className="testimonial__area pt-145 pb-150"
        style={{
          background: `url(/assets/img/testimonial/home-3/testimonial-bg-3.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10">
              <div className="testimonial__slider-3">
                <h3 className="testimonial__title">Emerge Career Services</h3>
                <div className="testimonial__slider-wrapper swiper-container testimonial-text mb-35">
                  <div style={{ color: "white" }}>
                    <ul
                      style={{
                        color: "white",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <li>
                        {" "}
                        <i
                          style={{ color: "white", fontSize: 10 }}
                          class="fa fa-circle"
                          aria-hidden="true"
                        ></i>
                        &nbsp; Upskill to become interview-ready
                      </li>
                      <p style={{ color: "white", fontWeight: 700 }}>
                        &nbsp; &nbsp; &nbsp;360 degree career service through :
                      </p>
                      {BulletList.map((item) => {
                        return (
                          <li>
                            <i
                              style={{ color: "white", fontSize: 10 }}
                              class="fa fa-circle"
                              aria-hidden="true"
                            ></i>
                            &nbsp;{item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10">
              <div className="testimonial__video ml-70 fix">
                <div className="testimonial__thumb-3">
                  <img src="/assets/img/ghimages/technology.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialThree;
