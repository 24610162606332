import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.body.scrollTop = document.body.scrollHeight;
  }, []);
  return (
    <Wrapper>
      <SEO pageTitle={"Privacy Policy"} />
      <div className="page__title-shape">
        <img
          className="page-title-shape-5 d-none d-sm-block"
          src="/assets/img/page-title/page-title-shape-1.png"
          alt=""
        />
        <img
          className="page-title-shape-6"
          src="/assets/img/page-title/page-title-shape-6.png"
          alt=""
        />
        <img
          className="page-title-shape-7"
          src="/assets/img/page-title/page-title-shape-4.png"
          alt=""
        />
      </div>

      <div style={{ marginTop: 20 }} className="container">
        <h2>Privacy Policy for Achillobator Technology Private Limited</h2>
        <p>
          The Achillobator Privacy Policy covers how Achillobator collects,
          uses, discloses, transfers, and stores your information. Your privacy
          is important to Achillobator and to ensure that your personal
          information is secure, Achillobator communicates its privacy and
          security guidelines to Achillobator employees and strictly enforces
          privacy safeguards within the company. Please familiarize yourself
          with our privacy practices. 1. Collection & Usage of Personal
          Information Personal information is data that can be used to uniquely
          identify or contact a single person. You may be asked to provide your
          personal information anytime you are in contact with Achillobator or
          Mygreenhorn affiliates or Achillobator authorized business partners
          (which includes agents, contractors, vendors, licensors, resellers,
          system integrators, etc.). When you purchase Achillobator services,
          register for utilizing Achillobator services, contact Achillobator to
          report service issues, apply for credit, or participate in an online
          survey, Mygreenhorn, its affiliates or authorized business partners
          may collect various information, including your name, mailing address,
          phone number, email address, contact preferences, unique device
          identifier and credit card information. Mygreenhorn, its affiliates
          and its business partners may share this personal information with
          each other and use it consistent with this Privacy Policy. They may
          also combine it with other information to provide and improve
          Mygreenhorn’s services, content, and advertising. The personal
          information Achillobator collects allows it to keep you posted on the
          latest services announcements, services updates and upcoming events.
          It also helps Achillobator to improve its content, and advertising.
          You can opt out by sending a notice to our return email address at any
          time. Please note that opting out may impact your ability to use some
          Achillobator services. Achillobator may use your personal information
          to send important notices, such as communications about purchases,
          changes to the terms, conditions, and policies, your
          compliance/non-compliance with the terms and conditions of usage of
          Achillobator services, etc. Because this information is important to
          your interaction with Mygreenhorn, you may not opt out of receiving
          these communications. Achillobator may also use personal information
          for internal purposes such as auditing, data analysis, and research to
          improve Mygreenhorn’s services, and customer communications. If you
          enter into a sweepstake, contest, or similar promotion Achillobator
          may use the information you provide to administer those programs. 2.
          Collection & Usage of Non-Personal Information Non-personal
          information is data in a form that does not permit direct association
          with any specific individual. Achillobator may collect, use, transfer,
          and disclose non-personal information for any purpose. Achillobator
          may collect information such as unique device identifier, location,
          details on services usage and the time zone where a Mygreenhorn
          service is used so that Achillobator can better understand customer
          behaviour and improve its services, and advertising. Where applicable,
          Achillobator may collect and review your content to ensure your
          compliance with the terms and conditions of usage of its services,
          etc. Achillobator also may collect information regarding customer
          activities on the Achillobator website. This information is aggregated
          and used to provide more useful information to its customers and to
          understand which parts of the Achillobator website, products, and
          services are of most interest. Aggregated data is considered
          non-personal information for the purposes of this Privacy Policy. If
          Achillobator combines non-personal information with personal
          information the combined information will be treated as personal
          information, as long as it remains combined and will be subject to the
          policy mentioned in section 1 above. 3. Cookies & Other Technologies
          Mygreenhorn’s website, online services, interactive applications,
          email messages, and advertisements may use “cookies” and other
          technologies such as pixel tags and web beacons. These technologies
          enable Achillobator to understand user behaviour, identify which parts
          of the website the users have visited, and facilitate and measure the
          effectiveness of advertisements and web searches. Achillobator treats
          information collected by cookies and other technologies as
          non-personal information. However, to the extent that Internet
          Protocol (IP) addresses or similar identifiers are considered personal
          information by local law, Achillobator also treats these identifiers
          as personal information. Similarly, to the extent that non-personal
          information is combined with personal information, Achillobator treats
          the combined information as personal information for the purposes of
          this Privacy Policy. Achillobator and its partners may also use
          cookies and other technologies to remember personal information when
          you use the website, online services, and products/services. The
          objective in such instances is to make your experience with
          Achillobator more convenient and personal. When you visit the website,
          Achillobator may gather some information automatically and store it in
          log files. This information includes Internet Protocol (IP) addresses,
          browser type and language, Internet service provider (ISP), referring
          and exit pages, operating system, date/time stamp, and clickstream
          data. Achillobator uses this information to understand and analyse
          trends, to administer the site, to learn about user behaviour on the
          site, and to gather demographic information about its user base as a
          whole. Achillobator may use this information in its marketing and
          advertising. In some of its email messages, Achillobator may use a
          “click-through URL” linked to content on the Achillobator website.
          When customers click one of these URLs, they pass through a separate
          web server before arriving at the destination page on the website.
          This click-through data is tracked to enable the determination of
          interest in particular topics and measure the effectiveness of the
          customer communications. If you prefer not to be tracked in this way,
          you should not click text or graphic links in the email messages.
          Pixel tags enable Achillobator to send email messages in a format
          customers can read, and they indicate whether mail has been opened.
          Mygreenhorn may use this information to reduce or eliminate messages
          sent to customers. 4. Disclosure to Third Parties At times
          Achillobator may make certain personal information available to
          partners that work with Achillobator to provide services, or that help
          Achillobator market to customers. For example, when you purchase
          Achillobator services, you authorize Achillobator and its service
          providers to exchange the information you provide during the
          activation process to carry out services. If you are approved for
          services, your account will be governed by Achillobator and its
          service providers’ privacy policies. Personal information will only be
          used by Achillobator to provide or improve its services and
          advertising; it will not be shared with third parties for their
          marketing purposes. Achillobator shares personal information with
          partners who provide services such as information processing,
          extending credit, fulfilling customer orders, managing and enhancing
          customer data, providing customer service, assessing your interest in
          Achillobator services, and conducting customer research or
          satisfaction surveys. These companies are obligated to protect your
          information and may be located wherever Achillobator operates. It may
          be necessary − by law, legal process, litigation, and/or requests from
          public and governmental authorities within or outside your country of
          residence − for Achillobator to disclose your personal information.
          Achillobator may also disclose information about you if Mygreenhorn
          determines that for purposes of national security, law enforcement, or
          other issues of public importance, disclosure is necessary or
          appropriate. Achillobator may also disclose relevant information if
          Achillobator determines that disclosure is reasonably necessary to
          enforce its terms and conditions or protect its operations or users.
          Additionally, in the event of a reorganization, merger, or sale,
          Achillobator may transfer any and all personal information Mygreenhorn
          collect to the relevant third-party. 5. Protection of Personal
          Information Achillobator takes precautions including administrative,
          technical, and physical measures to safeguard your personal
          information against loss, theft, and misuse, as well as against
          unauthorized access, disclosure, alteration, and destruction. When you
          use some Achillobator services or post on a Achillobator forum, chat
          room, or social networking service, the personal information you share
          is visible to other users and can be read, collected, or used by them.
          You are responsible for the personal information you choose to submit
          in these instances. 6. Integrity and Retention of Personal Information
          Achillobator will continue to expand your ability and make it easy for
          you to keep your personal information accurate, complete, and up to
          date. Achillobator will retain your personal information for the
          period necessary to fulfil the purposes outlined in this Privacy
          Policy unless a longer retention period is required or prescribed by
          law. 7. Access to Personal Information For certain services,
          Mygreenhorn requests user personal data such as email address
          information to complete registration and authenticate you as a valid
          user . In the future, as additional information is required for
          renewals or future registrations, Achillobator will make good faith
          efforts to provide you with access so that you can request for
          Achillobator to correct the data if it is inaccurate or delete the
          data if Achillobator is not required to retain it by law or for
          legitimate business purposes. Achillobator may decline to process
          requests that are unreasonably repetitive, require disproportionate
          technical effort, jeopardize the privacy of others, are extremely
          impractical, or for which access is not otherwise required by local
          law. 8. Third-Party Sites and Services Achillobator websites and
          services may contain links to third-party websites, products, and
          services. Achillobator and services may reference other sites offered
          by third parties. Information collected by third parties, which may
          include additional information or requirements, is governed by their
          respective privacy practices. Achillobator encourages you to learn
          about the privacy practices of those third parties. 9. Users who fill
          enrolment form or any other form capturing user data on forms
          generated on social media channels or on Mygreenhorn’s website agrees
          to expressly authorize Mygreenhorn to send service related
          transactional SMS messages and gives them permission to call them,
          even in the case that Member's contact number is listed as DND with
          the concerned telecom regulatory authority. In a few instances we have
          used content created by other creators that are available on public
          domains such as YouTube. We would like to confirm that these are used
          only for the benefit of the society to meet the ends of educational
          purposes as per the Doctrine of Fair Use Policy. We would like to
          thank the creators and also provide them credits for their content. In
          case the creators feel that we should not be using their content, they
          can inform us by emailing to hello@mygreenhorn.com and we will take
          necessary action to take the content off our platform at the earliest.{" "}
        </p>
      </div>
    </Wrapper>
  );
};

export default PrivacyPolicy;
