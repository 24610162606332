import React from "react";
import Footer from "../../layout/footers/footer";
import Header from "../../layout/headers/header";
import Cta from "../common/cta/cta";
import BannerArea from "../common/banner-area";
import CategoryArea from "./category-area";
import Courses from "../common/course/courses";
import EventArea from "./event-area";
import HeroArea from "./hero-area";
import Pricing from "./pricing";
import { Link } from "react-router-dom";
import CareersPath from "./careerspath";
import MixEvents from "./mixevents";
import { getDataFromLocalStorage } from "../../browser-storage";
const index = () => {
  return (
    <>
      {/* hero start */}
      <HeroArea />
      {/* hero end */}

      {/* category start */}
      <CategoryArea />
      {/* category end */}

      {/* banner start */}
      <BannerArea />
      {/* banner end */}

      {/* course start */}
      {/* <Courses /> */}
      {/* course end */}

      {<CareersPath />}

      {/* event start */}
      {/* <EventArea /> */}
      {/* event end */}
      {/* Commented till we create blogs and webinars */}
      {/* {<MixEvents/>} */}

      {/* pricing start */}
      <div style={{ marginTop: 40 }}>
        <Pricing />
      </div>
      {/* pricing end */}

      {/* cta start */}
      <Cta />
      {/* cta end */}
    </>
  );
};

export default index;
