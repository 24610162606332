import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { signupv2 } from "../../../services/user-service";

import ErrorMsg from "./error-msg";
import { registerSchema } from "./validation-schema";
import { toast } from "react-toastify";
const RegisterForm = () => {
  // register form
  const handleOnSubmit = async (values, { resetForm }) => {
    console.log("Values", values);
    //alert(`${values.name + "\n" + values.email + "\n" + values.password}`);

    const res = await signupv2(
      values.email,
      values.password,
      values.firstName,
      values.lastName,
      values.mobile
    )
      .then((res) => {
        console.log(res);
        toast.success(`Successfully Created`, {
          position: "top-left",
        });
      })
      .catch((err) => {
        toast.error(`Error:${err}`, {
          position: "top-left",
        });
      });

    //resetForm()
  };
  // use formik
  const { handleChange, handleSubmit, handleBlur, errors, values, touched } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      },
      validationSchema: registerSchema,
      onSubmit: handleOnSubmit,
    });

  return (
    <form onSubmit={handleSubmit}>
      <div className="sign__input-wrapper mb-25">
        <h5>First Name</h5>
        <div className="sign__input">
          <input
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            placeholder="First Name"
            name="firstName"
          />
          <i className="fal fa-user"></i>
        </div>
        {touched.firstName && <ErrorMsg error={errors.firstName} />}
      </div>
      <div className="sign__input-wrapper mb-25">
        <h5>Last Name</h5>
        <div className="sign__input">
          <input
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            placeholder="Last Name"
            name="lastName"
          />
          <i className="fal fa-user"></i>
        </div>
        {touched.lastName && <ErrorMsg error={errors.lastName} />}
      </div>
      <div className="sign__input-wrapper mb-25">
        <h5>Mobile Number</h5>
        <div className="sign__input">
          <input
            value={values.mobile}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            placeholder="Mobile Number"
            name="mobile"
          />
          <i className="fal fa-user"></i>
        </div>
        {touched.mobile && <ErrorMsg error={errors.mobile} />}
      </div>

      <div className="sign__input-wrapper mb-25">
        <h5>Username</h5>
        <div className="sign__input">
          <input
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            placeholder="e-mail address"
            name="email"
          />
          <i className="fal fa-envelope"></i>
        </div>
        {touched.email && <ErrorMsg error={errors.email} />}
      </div>

      <div className="sign__input-wrapper mb-25">
        <h5>Password</h5>
        <div className="sign__input">
          <input
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            placeholder="Password"
            name="password"
          />
          <i className="fal fa-lock"></i>
        </div>
        {touched.password && <ErrorMsg error={errors.password} />}
      </div>

      <div className="sign__input-wrapper mb-10">
        <h5>Re-Password</h5>
        <div className="sign__input">
          <input
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            placeholder="Re-Password"
            name="passwordConfirmation"
          />
          <i className="fal fa-lock"></i>
        </div>
        {touched.passwordConfirmation && (
          <ErrorMsg error={errors.passwordConfirmation} />
        )}
      </div>

      <div className="sign__action d-flex justify-content-between mb-30">
        <div className="sign__agree d-flex align-items-center">
          <input className="m-check-input" type="checkbox" id="m-agree" />
          <label className="m-check-label" htmlFor="m-agree">
            I agree to the <a href="#">Terms & Conditions</a>
          </label>
        </div>
      </div>
      <button type="submit" className="e-btn w-100">
        {" "}
        <span></span> Sign Up
      </button>
      <div className="sign__new text-center mt-20">
        <p>
          Already in Emerge finishing school ?{" "}
          <Link to="/sign-in"> Sign In</Link>
        </p>
      </div>
    </form>
  );
};

export default RegisterForm;
