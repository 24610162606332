import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    console.log("auth",auth)
localStorage.setItem('Auth',JSON.stringify(auth))
const localauth = localStorage.getItem('Auth')
console.log("localauth",localauth)
const localauthparsed = JSON.parse(localauth)
console.log(localauthparsed)
const localrole = localauthparsed.role
console.log(localrole)
const localrolearr = [localrole]

    console.log("AuthProvider",auth)
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;