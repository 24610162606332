import Wrapper from "../../layout/wrapper";

import { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import "./jobs.css";
import Carousel from "react-multi-carousel";
import { Link, useNavigate } from "react-router-dom";

import JobSlice from "./jobslice";
import { toast } from "react-toastify";
import { toBase64 } from "../../helpers/index";
import styled from "styled-components";
import { getJoblisting, applyJobListing } from "../../services/jobs-service";
import Brands from "../../components/common/brand/brands";

const Jobs = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [resume, setResume] = useState({
    uploadedFile: undefined,
    fileUrl: "",
    fullname: "",
    username: "",
  });
  const [showErrors, setShowErrors] = useState(false);

  const setupResume = async (file) => {
    console.log("setupresume", file);
    if (file?.length && file[0]?.size > 4_00_00000) {
      setShowErrors(true);
      return;
    }
    const base64 = await toBase64(file[0]).catch(() =>
      //add toast fail here
      console.log("err")
    );
    if (base64) {
      setResume({
        uploadedFile: file[0],
        fileUrl: base64,
        fullname: "",
        username: "",
      });
    }
    console.log("fileUrl", resume.fileUrl);
    setShowErrors(false);
  };

  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 6, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const [singleJobobj, setsingleJobobj] = useState();
  const uploadImage = async () => {
    if (!resume.uploadedFile || showErrors) {
      return;
    }

    //this is a service function which is responsible for upload
    // uploadresumeBuilder(resume.uploadedFile)
    //   .then(async (data) => {
    const _user = localStorage.getItem("user");
    const userDetials = JSON.parse(_user);
    const email = userDetials.email;
    const e = singleJobobj;
    const payload = { e, email };
    console.log("payload", payload);
    const resp = await applyJobListing(payload)
      .then((res) => {
        console.log(res);
        toast.success(`Job Applied Successfully`, {
          position: "top-left",
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // })
    // .catch(() =>
    //   toast.error(`Unable to Apply for the Job`, {
    //     position: "top-left",
    //   })
    // )
    // .finally();
    closeModal();
  };

  let ITJobs = [];
  let Sales = [];

  useEffect(() => {
    //getJobsCategoryCount();
  }, []);

  let getJobsCategoryCount = async () => {
    const resp = await getJoblisting()
      .then((res) => {
        console.log(res);
        let reverseres = res.reverse();
        for (let category of reverseres) {
          if (category.job_function == "Sales") {
            Sales.push(category);
          }
        }
        console.log("Sales", Sales.length, Sales);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Wrapper>
        <div className="page-wraper">
          {/* CONTENT START */}

          <div className="page-content">
            {/*Banner Start*/}
            <div
              className="twm-home1-banner-section site-bg-gray bg-cover"
              style={{
                backgroundImage: "url(/assets/img/ghjob/bg1.jpg)",
              }}
            >
              <div className="row">
                {/*Left Section*/}
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="twm-bnr-left-section">
                    {/* <div className="twm-bnr-title-small">
                      We Have{" "}
                      <span className="site-text-primary">208,000+</span> Live
                      Jobs
                    </div> */}
                    <div className="twm-bnr-title-large">
                      Find the <span className="site-text-primary">job</span>{" "}
                      that fits your skills
                    </div>
                    {/* <div className="twm-bnr-discription">
                      Type your keyword, then click search to find your perfect
                      job.
                    </div> */}

                    {/* <div className="twm-bnr-search-bar">
                      <form>
                        <div className="row"> */}
                    {/*Title*/}
                    {/* <div className="form-group col-xl-3 col-lg-6 col-md-6">
                            <label>What</label>
                            <select
                              className="wt-search-bar-select selectpicker"
                              data-live-search="true"
                              title=""
                              id="j-Job_Title"
                              data-bv-field="size"
                            >
                              <option disabled selected value="">
                                Select Category
                              </option>
                              <option selected>Job Title</option>
                              <option>Web Designer</option>
                              <option>Developer</option>
                              <option>Acountant</option>
                            </select>
                          </div> */}

                    {/*All Category*/}
                    {/* <div className="form-group col-xl-3 col-lg-6 col-md-6">
                            <label>Type</label>
                            <select
                              className="wt-search-bar-select selectpicker"
                              data-live-search="true"
                              title=""
                              id="j-All_Category"
                              data-bv-field="size"
                            >
                              <option disabled selected value="">
                                Select Category
                              </option>
                              <option selected>All Category</option>
                              <option>Web Designer</option>
                              <option>Developer</option>
                              <option>Acountant</option>
                            </select>
                          </div> */}

                    {/*Location*/}
                    {/* <div className="form-group col-xl-3 col-lg-6 col-md-6">
                            <label>Location</label>
                            <div className="twm-inputicon-box">
                              <input
                                name="username"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Search..."
                              />
                              <i className="twm-input-icon fas fa-map-marker-alt"></i>
                            </div>
                          </div> */}

                    {/*Find job btn*/}
                    {/* <div className="form-group col-xl-3 col-lg-6 col-md-6">
                            <button type="button" className="site-button">
                              Find Job
                            </button>
                          </div> */}
                    {/* </div>
                      </form>
                    </div> */}

                    {/* <div className="twm-bnr-popular-search">
                      <span className="twm-title">Popular Searches:</span>
                      <a href="job-list.html">Developer</a> ,
                      <a href="job-list.html">Designer</a> ,
                      <a href="job-list.html">Architect</a> ,
                      <a href="job-list.html">Engineer</a> ...
                    </div> */}
                  </div>
                </div>

                {/*right Section*/}
                <div className="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
                  <div className="twm-bnr-right-content">
                    <div className="twm-img-bg-circle-area">
                      <div className="twm-img-bg-circle1 rotate-center">
                        <span></span>
                      </div>
                      <div className="twm-img-bg-circle2 rotate-center-reverse">
                        <span></span>
                      </div>
                      <div className="twm-img-bg-circle3">
                        <span></span>
                      </div>
                    </div>

                    <div className="twm-bnr-right-carousel">
                      <div className="owl-carousel twm-h1-bnr-carousal">
                        <div className="item">
                          <div className="slide-img">
                            <div className="desktop">
                              <img
                                width="600px"
                                height="800px"
                                className="sliderimage"
                                src="/assets/img/hero/girls/6.png"
                                alt=""
                              />
                            </div>
                            <div className="mobile">
                              <img
                                className=""
                                src="/assets/img/hero/girls/6.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="item">
                          <div className="slide-img">
                            <div className="slide-img">
                              <img src="/assets/img/ghjob/r-img2.png" alt="" />
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div className="twm-bnr-blocks-position-wrap">
                        {/*icon-block-1*/}
                        <div className="twm-bnr-blocks twm-bnr-blocks-position-1">
                          <div className="twm-icon">
                            <img src="/assets/img/ghjob/icon-1.png" alt="" />
                          </div>
                          <div className="twm-content">
                            <div className="tw-count-number text-clr-sky">
                              <span className="counter">
                                <CountUp end={2000} />
                              </span>
                              +
                            </div>
                            <p className="icon-content-info">
                              Job Openings every week.
                            </p>
                          </div>
                        </div>

                        {/*icon-block-2*/}
                        <div className="twm-bnr-blocks twm-bnr-blocks-position-2">
                          <div className="twm-icon">
                            <img src="/assets/img/ghjob/icon-2.png" alt="" />
                          </div>
                          <div className="twm-content">
                            <div className="tw-count-number text-clr-pink">
                              <span className="counter">
                                <CountUp end={5} />
                              </span>{" "}
                              +
                            </div>
                            <p className="icon-content-info">
                              Lakh average salary{" "}
                            </p>
                          </div>
                        </div>

                        {/*icon-block-3*/}
                        <div className="twm-bnr-blocks-3 twm-bnr-blocks-position-3">
                          <div className="twm-icon">
                            <img
                              width="70"
                              src="/assets/img/ghjob/job-icon.png"
                              alt=""
                            />
                          </div>
                          <div
                            style={{ marginLeft: 25 }}
                            className="twm-content"
                          >
                            <div className="tw-count-number text-clr-green">
                              <span className="counter">
                                <CountUp end={200} />
                              </span>
                              +
                            </div>
                            <p className="icon-content-info">
                              Companies Hiring
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*Samll Ring Left*/}
                    <div className="twm-small-ring-l slide-top-animation"></div>
                    <div className="twm-small-ring-2 slide-top-animation"></div>
                  </div>
                </div>
              </div>
              <div className="twm-gradient-text">Jobs</div>
            </div>
            {/*Banner End*/}

            {/* HOW IT WORK SECTION START */}
            <div className="section-full p-t120 p-b90 site-bg-white twm-how-it-work-area">
              <div className="container">
                {/* TITLE START*/}
                <div className="section-head center wt-small-separator-outer">
                  <div className="wt-small-separator site-text-primary">
                    {/* <div>Working Process</div> */}
                  </div>
                  <h2 className="wt-title site-text-primary">How It works</h2>
                </div>
                {/* TITLE END*/}

                <div className="twm-how-it-work-section">
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6">
                      <div className="twm-w-process-steps">
                        <span className="twm-large-number">01</span>
                        <div className="twm-w-pro-top bg-clr-sky">
                          <div className="twm-media">
                            <span>
                              <img
                                src="/assets/img/ghjob/work-process/icon1.png"
                                alt="icon1"
                              />
                            </span>
                          </div>
                          <h4 className="twm-title">
                            Sign up
                            <br />
                            For Free
                          </h4>
                        </div>
                        {/* <p>
                          You need to create an account to find the best and
                          preferred job.
                        </p> */}
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6">
                      <div className="twm-w-process-steps">
                        <span className="twm-large-number">02</span>
                        <div className="twm-w-pro-top bg-clr-pink">
                          <div className="twm-media">
                            <span>
                              <img
                                src="/assets/img/ghjob/work-process/icon2.png"
                                alt="icon1"
                              />
                            </span>
                          </div>
                          <h4 className="twm-title">
                            Find <br />
                            Your Dream Job
                          </h4>
                        </div>
                        {/* <p>
                          You need to create an account to find the best and
                          preferred job.
                        </p> */}
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-6">
                      <div className="twm-w-process-steps">
                        <span className="twm-large-number">03</span>
                        <div className="twm-w-pro-top bg-clr-green">
                          <div className="twm-media">
                            <span>
                              <img
                                src="/assets/img/ghjob/work-process/icon3.png"
                                alt="icon1"
                              />
                            </span>
                          </div>
                          <h4 className="twm-title">
                            Apply <br />
                            With Your Resume
                          </h4>
                        </div>
                        {/* <p>
                          You need to create an account to find the best and
                          preferred job.
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* HOW IT WORK SECTION END */}

            {/* JOBS CATEGORIES SECTION START */}
            <div className="section-full p-t120 p-b90 site-bg-gray twm-job-categories-area">
              <div className="container">
                <div className="wt-separator-two-part">
                  <div className="row wt-separator-two-part-row">
                    <div className="col-xl-5 col-lg-5 col-md-12 wt-separator-two-part-left">
                      {/* TITLE START*/}
                      <div className="section-head left wt-small-separator-outer">
                        <div className="wt-small-separator site-text-primary">
                          <div>Jobs by Category</div>
                        </div>
                        {/* <h2 className="wt-title">
                          Choose Your Desire Category
                        </h2> */}
                      </div>
                      {/* TITLE END*/}
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right">
                      {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry the standard dummy text ever since
                        the when an printer took.
                      </p> */}
                    </div>
                  </div>
                </div>

                <div className="twm-job-categories-section">
                  <div className="job-categories-style1 m-b30">
                    <div className="owl-carousel job-categories-carousel owl-btn-left-bottom ">
                      <div className="row">
                        <div style={{ marginTop: 10 }} className="col-md-3">
                          {/* COLUMNS 1 */}
                          <div className="item ">
                            <div className="job-categories-block">
                              <div className="twm-media">
                                <div className="iconcontainer">
                                  <img src="/assets/img/ghjob/jobicon/business.png" />
                                </div>
                              </div>
                              <div className="twm-content">
                                {/* <div className="twm-jobs-available">
                                  9,185 Jobs
                                </div> */}

                                <div>Business Operations</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 10 }} className="col-md-3">
                          {/* COLUMNS 2 */}
                          <div className="item ">
                            <div className="job-categories-block">
                              <div className="twm-media">
                                <div className="iconcontainer">
                                  <img src="/assets/img/ghjob/jobicon/operation.png" />
                                </div>
                              </div>
                              <div className="twm-content">
                                {/* <div className="twm-jobs-available">
                                  3,205 Jobs
                                </div> */}

                                <div>Core Engineering</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 10 }} className="col-md-3">
                          {/* COLUMNS 3 */}
                          <div className="item ">
                            <div className="job-categories-block">
                              <div className="twm-media">
                                <div className="iconcontainer">
                                  <img src="/assets/img/ghjob/jobicon/it.png" />
                                </div>
                              </div>
                              <div className="twm-content">
                                {/* <div className="twm-jobs-available">
                                  2,100 Jobs
                                </div> */}

                                <div>Information Technology</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 10 }} className="col-md-3">
                          {/* COLUMNS 4 */}
                          <div className="item ">
                            <div className="job-categories-block">
                              <div className="twm-media">
                                <div className="iconcontainer">
                                  <img src="/assets/img/ghjob/jobicon/hr.png" />
                                </div>
                              </div>
                              <div className="twm-content">
                                {/* <div className="twm-jobs-available">
                                  1,500 Jobs
                                </div> */}

                                <div>Human Resources</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: 20 }} className="row">
                        <div className="col-md-3">
                          {/* COLUMNS 5 */}
                          <div style={{ marginTop: 10 }} className="item ">
                            <div className="job-categories-block">
                              <div className="twm-media">
                                <div className="iconcontainer">
                                  <img src="/assets/img/ghjob/jobicon/marketing.png" />
                                </div>
                              </div>
                              <div className="twm-content">
                                {/* <div className="twm-jobs-available">
                                  9,185 Jobs
                                </div> */}

                                <div>Marketing</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 10 }} className="col-md-3">
                          {/* COLUMNS 6 */}
                          <div className="item ">
                            <div className="job-categories-block">
                              <div className="twm-media">
                                <div className="iconcontainer">
                                  <img src="/assets/img/ghjob/jobicon/sale.png" />
                                </div>
                              </div>
                              <div className="twm-content">
                                {/* <div className="twm-jobs-available">
                                  3,205 Jobs
                                </div> */}

                                <div>Sales</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: 10 }} className="col-md-3">
                          {/* COLUMNS 7 */}
                          <div className="item ">
                            <div className="job-categories-block">
                              <div className="twm-media">
                                <div className="iconcontainer">
                                  <img src="/assets/img/ghjob/jobicon/other.png" />
                                </div>
                              </div>
                              <div className="twm-content">
                                {/* <div className="twm-jobs-available">
                                  2,100 Jobs
                                </div> */}

                                <div>Others</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* COLUMNS 8 */}
                        {/* <div className="col-md-3">
                          <div className="item ">
                            <div className="job-categories-block">
                              <div className="twm-media">
                                <div className="flaticon-coding"></div>
                              </div>
                              <div className="twm-content">
                                <div className="twm-jobs-available">
                                  1,500 Jobs
                                </div>
                                <a href="job-detail.html">Web Development</a>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="text-right job-categories-btn">
                    <a href="job-grid.html" className=" site-button">
                      All Categories
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            {/* JOBS CATEGORIES SECTION END */}

            {/* EXPLORE NEW LIFE START */}
            <div
              className="section-full p-t120 twm-explore-area bg-cover "
              style={{
                backgroundImage: "url(/assets/img/ghjob/background/bg-1.jpg)",
              }}
            >
              <div className="container">
                <div className="section-content">
                  <div className="row">
                    <div className="col-lg-4 col-md-12">
                      <div className="twm-explore-media-wrap">
                        <div className="twm-media">
                          <img src="/assets/img/hero/girls/4.png" alt="" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-12">
                      <div className="twm-explore-content-outer">
                        <div className="twm-explore-content">
                          <div className="twm-l-line-1"></div>
                          <div className="twm-l-line-2"></div>

                          <div className="twm-r-circle-1"></div>
                          <div className="twm-r-circle-2"></div>

                          {/* <div className="twm-title-small">
                            Explore New Life
                          </div> */}
                          <div className="twm-title-large">
                            <h2>Find amazing job opportunities inside! </h2>
                            {/* <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry the standard dummy text
                              ever since the when an printer took.
                            </p> */}
                          </div>
                          <div className="twm-upload-file">
                            <Link to="/student-login">
                              <button type="button" className="site-button">
                                Apply with your Resume{" "}
                                <i className="feather-upload"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="twm-bold-circle-right"></div>
                        <div className="twm-bold-circle-left"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* EXPLORE NEW LIFE END */}
            {/* <Brands /> */}
            {/* TOP COMPANIES START */}
            <div className="section-full p-t120  site-bg-white twm-companies-wrap">
              {/* TITLE START*/}
              <div className="section-head center wt-small-separator-outer">
                {/* <div className="wt-small-separator site-text-primary">
                  <div>Top Companies</div>
                </div> */}
                <h2 className="wt-title site-text-primary">Hiring Companies</h2>
              </div>
              {/* TITLE END*/}
              <div className="container">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="owl-carousel home-client-carousel2 owl-btn-vertical-center">
                      <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={false}
                        autoPlay={false}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                      >
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/1.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/2.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/3.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/4.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/5.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/6.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/7.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/8.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/9.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/10.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/11.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/12.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/13.png"
                            alt=""
                          />
                        </div>
                        {/* <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/14.png"
                            alt=""
                          />
                        </div> */}
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/15.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/16.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/17.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/18.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/19.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/20.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/21.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/22.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/23.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/24.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/25.png"
                            alt=""
                          />
                        </div>
                        {/* <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/26.png"
                            alt=""
                          />
                        </div> */}
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/27.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/28.png"
                            alt=""
                          />
                        </div>
                        <div className="item">
                          <img
                            src="/assets/img/ghjob/client-logo/29.png"
                            alt=""
                          />
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="twm-company-approch-outer">
                <div className="twm-company-approch">
                  <div className="row">
                    
                    <div className="col-lg-4 col-md-12">
                      <div className="counter-outer-two">
                        <div className="icon-content">
                          <div className="tw-count-number text-clr-sky">
                            <span className="counter">
                              <CountUp end={5} />
                            </span>
                            M+
                          </div>
                          <p className="icon-content-info">
                            Million daily active users
                          </p>
                        </div>
                      </div>
                    </div>

                 
                    <div className="col-lg-4 col-md-12">
                      <div className="counter-outer-two">
                        <div className="icon-content">
                          <div className="tw-count-number text-clr-pink">
                            <span className="counter">
                              <CountUp end={9} />
                            </span>
                            K+
                          </div>
                          <p className="icon-content-info">
                            Open job positions
                          </p>
                        </div>
                      </div>
                    </div>

                  
                    <div className="col-lg-4 col-md-12">
                      <div className="counter-outer-two">
                        <div className="icon-content">
                          <div className="tw-count-number text-clr-green">
                            <span className="counter">
                              <CountUp end={2} />
                            </span>
                            M+
                          </div>
                          <p className="icon-content-info">
                            Million stories shared
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* TOP COMPANIES END */}

            {/* JOB POST START */}
            <div className="section-full p-t120 p-b90 site-bg-light-purple twm-bg-ring-wrap">
              <div className="twm-bg-ring-right"></div>
              <div className="twm-bg-ring-left"></div>
              <div className="container">
                {/* TITLE START*/}
                <div className="section-head center wt-small-separator-outer">
                  {/* <div className="wt-small-separator site-text-primary">
                    <div>All Jobs Posts</div>
                  </div> */}
                  <h2 className="wt-title site-text-primary">Job Listings</h2>
                </div>
                {/* TITLE END*/}

                <div className="section-content">
                  <div className="container text-center">
                    <JobSlice />
                    <div className="text-center m-b30">
                      <a
                        onClick={() => navigate("/job-listings")}
                        className="site-button"
                      >
                        View All Jobs
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* JOB POST END */}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Jobs;
