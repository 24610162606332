import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  AddStudentEnquiryForm,
  AddStudentToMentorEnquiryForm,
} from "../../services/user-service";
import "./enquiryform.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";
import YouTube from "react-youtube";
const Video = styled.iframe`
  display: flex;
  align-items: center;
  border: none;
  max-width: 490px;
  width: 100%;
  height: 100%;
  max-height: 276px;
  min-height: 276px;
  box-shadow: 9px 14px 15px rgba(0, 0, 0, 0.25);
`;
const StudentToMentorEnquiryForm = () => {
  const location = useLocation();

  useEffect(() => {
    setMentorPredefined();
  }, []);

  let setMentorPredefined = async () => {
    console.log(location, " useLocation Hook");

    const MentorDetials = location.state?.data;
    console.log("MentorDetials", MentorDetials);
    setDetails({
      mentorfullname: MentorDetials.fullName,
      mentorEmailId: MentorDetials.userEmailId,
    });
  };

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const CurrentDate = new Date()
    .toJSON()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("/");
  const [details, setDetails] = useState({
    fullname: "",
    mobile: "",
    preferreddomain: "",
    submitteddate: CurrentDate,
    mentorfullname: "",
    mentorEmailId: "",
  });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!details.fullname || !details.mobile) {
      return;
    }

    const SubmitResponse = await AddStudentToMentorEnquiryForm(details)
      .then((resp) => {
        setDetails({ fullname: "", mobile: "", preferreddomain: "" });
        toast.success(`Submitted Successfully`, {
          position: "top-left",
        });
      })
      .catch((err) =>
        toast.error(`Error ${err}`, {
          position: "top-left",
        })
      );
  };

  const opts = {
    width: "100%",
    height: "265",

    playerVars: {
      autoplay: 0,
    },
  };

  let _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    videoCode: "FLrSxW9v9OA",
  });
  const setSeletedvideotoplay = (item) => {
    console.log("item", item);
    if (item == "Student1") {
      setCurrentvideotoplay({
        videoCode: "FLrSxW9v9OA",
      });
    }
    if (item == "Student2") {
      setCurrentvideotoplay({
        videoCode: "QqDya24v0R4",
      });
    }
    if (item == "GHIntro") {
      setCurrentvideotoplay({
        videoCode: "HQLEWpibxUY",
      });
    }
  };
  return (
    <Wrapper>
      <SEO pageTitle={"Student Enquiry Form"} />
      <div className="row">
        <div className="col-md-8">
          <div className="text-center">
            <div className="desktop">
              <YouTube
                className="cusmobilevideo"
                videoId={currentvidetoplay.videoCode}
                opts={opts}
                onReady={_onReady}
              />
            </div>
            <div className="mobile">
              <Video src={`https://www.youtube.com/embed/QqDya24v0R4`} />
            </div>
            <div className="container">
              <div className="row">
                <div
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  className="col-md-4"
                >
                  <img
                    onClick={() => setSeletedvideotoplay("Student1")}
                    src="/assets/img/ghimages/studentreviewimg.png"
                  />
                </div>
                <div
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  className="col-md-4"
                >
                  <img
                    onClick={() => setSeletedvideotoplay("Student2")}
                    src="/assets/img/ghimages/studentreviewing2.png"
                  />
                </div>
                <div
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  className="col-md-4"
                >
                  <img
                    onClick={() => setSeletedvideotoplay("GHIntro")}
                    src="/assets/img/ghimages/platformintro.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="padding20">
            <form onSubmit={submitHandler}>
              <div className="form-inner">
                <div className="form-group p-b-15">
                  <input
                    type="text"
                    name="mentorfullname"
                    id="mentorfullname"
                    placeholder="Full Name"
                    className="form-control "
                    value={details.mentorfullname}
                    autoComplete="off"
                    disabled
                  />
                </div>
                <div className="form-group p-b-15">
                  <input
                    type="text"
                    name="fullname"
                    id="fullname"
                    placeholder="Full Name"
                    onChange={(e) =>
                      setDetails({ ...details, fullname: e.target.value })
                    }
                    className="form-control "
                    value={details.fullname}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group p-b-15">
                  <input
                    type="number"
                    name="mobile"
                    placeholder="Mobile No."
                    autoComplete="off"
                    className="form-control "
                    onChange={(e) =>
                      setDetails({ ...details, mobile: e.target.value })
                    }
                    value={details.mobile}
                  />
                </div>
                {/* <div className="form-group">
                  <select
                    name="job_function"
                    className="form-control"
                    value={details.preferreddomain}
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        preferreddomain: e.target.value,
                      })
                    }
                    placeholder="Job Function (Categories)"
                  >
                    <option name="" value="" style={{ display: "none" }}>
                      Choose Category
                    </option>
                    <option
                      name="Business Operations"
                      value="Business Operations"
                    >
                      Business Operations
                    </option>
                    <option name="Core Engineering" value="Core Engineering">
                      Core Engineering
                    </option>
                    <option
                      name="Information Technology"
                      value="Information Technology"
                    >
                      Information Technology
                    </option>
                    <option name="Human Resources" value="Human Resources">
                      Human Resources
                    </option>
                    <option name="Marketing" value="Marketing ">
                      Marketing
                    </option>
                    <option name="Sales" value="Sales">
                      Sales
                    </option>
                    <option name="Others" value="Others">
                      Others
                    </option>
                  </select>
                </div> */}
              </div>
              {error != "" ? <div className="error">{error}</div> : ""}
              <input
                type="submit"
                value="Submit"
                style={{ marginTop: "20px" }}
                className="e-btn"
              />
            </form>
          </div>
        </div>
      </div>
      <></>
    </Wrapper>
  );
};

export default StudentToMentorEnquiryForm;
