import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectCourses } from "../../redux/features/course-slice";
import {
  add_to_wishlist,
  wishlistItems,
} from "../../redux/features/wishlist-slice";

const CareerPathData = [
  {
    id: "1",
    title: "Career in HR",
    shape_text: "Wording on badge",
    mentorname: "MyGreenHorn",
    teacherImg: "/assets/img/logo/GH1.png",
    courseImage: "/assets/img/hero/27r.png",
    category: "HR",
    navigeto: "/careerpathselected",
  },

  {
    id: "2",
    title: "Career in Customer Service and Operations",
    shape_text: "Wording on badge",
    mentorname: "MyGreenHorn",
    teacherImg: "/assets/img/logo/GH1.png",
    courseImage: "/assets/img/hero/19.png",
    category: "Business Operations",
    color: "sky-blue",
    navigeto: "/careerpathselected",
  },
  {
    id: "3",
    title: "Career in Sales",
    shape_text: "Wording on badge",
    mentorname: "MyGreenHorn",
    teacherImg: "/assets/img/logo/GH1.png",
    courseImage: "/assets/img/hero/30r.png",

    category: "Sales",
    color: "green",
    navigeto: "/careerpathselected",
  },
];

const course_info = {
  title: <>How to choose your right </>,
  shape_text: "career ?",
  title_2: "",
  subtitle: "Discuss with your mentor which career suits you best!",
};

const CareersPath = () => {
  const allCourses = useSelector(selectCourses);
  const [courses, setCourses] = useState(allCourses.slice(0, 6));
  const [active, setActive] = useState("all");
  const wishlists = useSelector(wishlistItems);
  console.log(wishlists);
  const dispatch = useDispatch();

  // handle wishlist
  const handleWishList = (course) => {
    dispatch(add_to_wishlist(course));
  };

  return (
    <>
      <section className="course__area pt-115 pb-120 grey-bg">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-xxl-5 col-xl-6 col-lg-6">
              <div className="section__title-wrapper mb-60">
                <h2
                  style={{ textTransform: "none" }}
                  className="section__title"
                >
                  {course_info.title}{" "}
                  <span className="yellow-bg yellow-bg-big">
                    {course_info.shape_text}
                    <img src="/assets/img/shape/yellow-bg.png" alt="" />
                  </span>{" "}
                  {course_info.title_2}
                </h2>
                <p>{course_info.subtitle}</p>
              </div>
            </div>
          </div>
          <div className="row grid">
            {CareerPathData.map((course, index) => (
              <div key={index} className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="course__item white-bg mb-30 fix">
                  <div className="course__thumb w-img p-relative fix">
                    <Link
                      to="/career-path-details"
                      state={{ data: course.category }}
                      className="link"
                    >
                      <img src={course.courseImage} alt="" />
                    </Link>
                    <div
                      onClick={() => handleWishList(course)}
                      className={`heart_icon 
                      ${
                        wishlists?.findIndex((i) => i.id === course.id) >= 0
                          ? "active"
                          : ""
                      }`}
                    >
                      <i className="fas fa-heart"></i>
                    </div>
                    <div className="course__tag">
                      <a href="#" className={course.color}>
                        {course.category}
                      </a>
                    </div>
                  </div>
                  <div className="course__content">
                    <div className="course__meta d-flex align-items-center justify-content-between"></div>
                    <h3 style={{ fontSize: 18 }} className="course__title">
                      <Link
                        to="/career-path-details"
                        state={{ data: course.category }}
                        className="link"
                      >
                        {course.title}
                      </Link>
                    </h3>
                    <div className="course__teacher d-flex align-items-center">
                      <div className="course__teacher-thumb mr-15">
                        <img
                          style={{ padding: 5 }}
                          src={course.teacherImg}
                          alt=""
                        />
                      </div>
                      <h6>
                        <Link
                          to="/career-path-details"
                          state={{ data: course.category }}
                          className="link"
                        >
                          {course.mentorname}
                        </Link>
                      </h6>
                    </div>
                  </div>
                  <div className="course__more d-flex justify-content-between align-items-center">
                    {/* <div className="course__status">
                      <span>Free</span>
                    </div> */}

                    <div className="course__btn">
                      <Link
                        to="/career-path-details"
                        state={{ data: course.category }}
                        className="link-btn"
                      >
                        Details
                        <i className="far fa-arrow-right"></i>
                        <i className="far fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CareersPath;
