import React, { useEffect, useState, useRef, useMemo } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";

import "../admin-portal/admin.css";
import { addJobListing } from "../../services/jobs-service";
import {
  getJoblisting,
  EditJobListing,
  DeleteJobListing,
  AppliedbyJobListing,
  viewAllUsers,
  GetJobsbyRecuiterId,
} from "../../services/jobs-service";

import config from "../../services/config";
import {
  setDataInLocalStorage,
  getDataFromLocalStorage,
} from "../../browser-storage";

import { toast } from "react-toastify";
import ExportAppliedbyJobs from "./exportappliedbyjobs";

//Later admined to seprate roles based dashboard

import JoditEditor from "jodit-react";
import * as DOMPurify from "dompurify";
const ref = React.createRef();
// const htmlpurify = DOMPurify()
const AddJob = () => {
  const editor = useRef(null);
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;

  const [companyName, setCompanyName] = useState("");
  const [jobDetails, setJobDetails] = useState({
    job_code: "",
    job_title: "",
    number_of_opening: "",
    listed_by: "",
    companyprofileImgUrl: "",
    job_function: "",
    exprience_category: "",
    qualification: "",
    type: "",
    location: "",
    start_date: formattedToday,
    salary: "",
    job_description: "",
    isremote: "",
    recuiter_email_id: "",
    isjobnew: true,
    isjobactive: true,
    isjobapproved: "false",
  });
  useEffect(() => {
    SetJobPredefinedValues();
  }, []);

  let SetJobPredefinedValues = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const CompanyDetials = getDataFromLocalStorage("company-details");
    console.log(CompanyDetials);
    const getCompanyName = CompanyDetials.companyname;
    const getCompanyProfile = CompanyDetials.companyprofileImgUrl;
    console.log("getCompanyName", getCompanyName);
    console.log("getCompanyName", getCompanyName);

    const currentrole = userDetials.role;
    const currentemail = userDetials.email;
    console.log(currentemail, currentrole);

    setJobDetails({
      ...jobDetails,
      listed_by: getCompanyName,
      companyprofileImgUrl: getCompanyProfile,
      recuiter_email_id: currentemail,
    });

    // setJobDetails({ dealersOverallTotal: total }, () => {
    //   console.log(this.state.dealersOverallTotal, "dealersOverallTotal1");
    // });

    console.log("jobDetails", jobDetails);
  };

  const [loginDetails, setLoginDetials] = useState({
    username: "",
    password: "",
  });
  const [isAdminLoggedin, SetAdminLoggedin] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [jobList, setjobList] = useState([]);
  const [appliedbylist, setAppliedbyList] = useState([]);
  const [selectedjob, setSelectedJob] = useState({ jobCode: "", jobTitle: "" });
  useEffect(() => {
    getALLJobListing();
  }, []);

  const getALLJobListing = async () => {
    const userDetials = getDataFromLocalStorage("user-details");
    console.log(userDetials);
    const currentrole = userDetials.role;
    const currentemail = userDetials.email;
    const resp = await GetJobsbyRecuiterId(currentemail)
      .then((res) => {
        let reverseres = res.reverse();
        setjobList(reverseres);
        console.log(jobList);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(jobList);
  };

  let AppliedbyJobHandler = (item) => {
    console.log(item);
    setSelectedJob({ jobCode: item.job_code, jobTitle: item.job_title });
    const job_code = item.job_code;
    const response = AppliedbyJobListing(job_code).then((resp) => {
      console.log("Response from appliedbyjob API", resp);
      const arrUniq = [
        ...new Map(
          resp.map((v) => [JSON.stringify([v.job_code, v.appliedby]), v])
        ).values(),
      ];
      console.log("Test arrUniq", arrUniq);
      setAppliedbyList(arrUniq);
    });

    console.log("respose", response);
  };

  let loginDetailsonChange = (evt) => {
    setLoginDetials({ ...loginDetails, [evt.target.name]: evt.target.value });
  };
  let jobDetailsonChange = (evt) => {
    console.log("evt", evt.target.value);
    setJobDetails({ ...jobDetails, [evt.target.name]: evt.target.value });
  };

  let jobsubmit = () => {
    console.log(jobDetails);
    const jobresp = addJobListing(jobDetails)
      .then((res) => {
        console.log(res);

        toast.success(`Job Added Successfully`, {
          position: "top-left",
        });
        getALLJobListing();
        setJobDetails({
          job_code: "",
          job_title: "",
          number_of_opening: "",
          listed_by: "",
          job_function: "",
          exprience_category: "",
          qualification: "",
          type: "",
          location: "",
          start_date: formattedToday,
          salary: "",
          job_description: "",
          isremote: "",
          isjobnew: true,
          isjobactive: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(jobresp);
  };

  let EditJobHandler = (item) => {
    console.log(item);
    console.log(item.job_code);
    setEditMode(true);
    setJobDetails({
      job_code: item.job_code,
      job_title: item.job_title,
      number_of_opening: item.number_of_opening,
      listed_by: item.listed_by,
      job_function: item.job_function,
      exprience_category: item.passoutyear,
      qualification: item.qualification,
      type: item.type,
      location: item.location,
      start_date: item.start_date,
      salary: item.salary,
      job_description: item.job_description,
      isremote: item.isremote,
      isjobnew: item.isjobnew,
      isjobactive: item.isjobactive,
    });
  };
  let EditJobSubmit = () => {
    console.log("jobDetails Edit", jobDetails);
    const editjobSubmit = EditJobListing(jobDetails)
      .then((res) => {
        console.log(res);

        toast.success(`Job Edited Successfully`, {
          position: "top-left",
        });
        getALLJobListing();
        setJobDetails({
          job_code: "",
          job_title: "",
          number_of_opening: "",
          listed_by: "",
          job_function: "",
          exprience_category: "",
          qualification: "",
          type: "",
          location: "",
          start_date: formattedToday,
          salary: "",
          job_description: "",
          isremote: "",
          isjobnew: true,
          isjobactive: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let DeleteJobHandler = (item) => {
    console.log(item);
    const job_code = item.job_code;
    DeleteJobListing(job_code).then((resp) => {
      console.log(resp);
    });
  };

  let switchtoaddmode = () => {
    setEditMode(false);
  };

  // let jobDescriptiononchange = (e) => {
  //   setJobDetails({ ...jobDetails, job_description: e.target.value })
  //   console.log(jobDetails.job_description)
  // }

  let richtext = (e) => {
    let clean = DOMPurify.sanitize(e);
    setJobDetails({ ...jobDetails, job_description: clean });
    console.log("jobDescription", jobDetails.job_description);
  };
  return (
    <>
      {/* <Container>
        {isAdminLoggedin == false ? (
          <>
            <Card className="MainCard">
              <h1>Admin Login</h1>
              <h1>Not Logged IN</h1>
              <Col sm={8}>
                <input type="text" name="username" className="form-control fieldcss" placeholder="Username" onChange={loginDetailsonChange} />
                <input type="password" name="password" className="form-control fieldcss" placeholder="Password" onChange={loginDetailsonChange} />
              </Col>
              <button className="btn btn-success" onClick={handlesubmit}>
                Submit
              </button>
            </Card>
          </>
        ) : null}
        {isAdminLoggedin == true ? (
          <> */}
      {/* <AdminHeader /> */}
      <Container>
        <Row style={{ marginTop: "50px" }}>
          <Col className="divscroll">
            <Card className="MainCard">
              <h1>All Job Listing</h1>
              {jobList.map((item) => {
                return (
                  <Card className="carddesign">
                    <Container className="jobcontainer">
                      <Row>
                        <p className="jobtitle">
                          <b className="boldtitle">{item.job_title}</b> (
                          {item.number_of_opening} Openings){" "}
                          <Badge bg="warning" text="dark">
                            New
                          </Badge>
                        </p>
                      </Row>
                      <Row>
                        <p className="companytitle">
                          <img
                            width="50px"
                            src="/images/blankcompany.png"
                            style={{ borderRadius: "50%" }}
                          />{" "}
                          &nbsp;&nbsp;{item.listed_by}
                        </p>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            <i class="fa-solid fa-indian-rupee-sign"></i>&nbsp;
                            {item.salary}&nbsp;Lakh
                          </p>
                        </Col>
                        <Col>
                          <p>
                            <i class="fa-solid fa-business-time"></i>&nbsp; 0-2
                            Years
                          </p>
                        </Col>
                        <Col>
                          <p>
                            <i class="fa-solid fa-business-time"></i>&nbsp;
                            {item.job_function}
                          </p>
                        </Col>
                        <Col>
                          <p>
                            <i class="fa-solid fa-location-dot"></i>&nbsp;
                            {item.location}
                          </p>
                        </Col>
                        <Col>
                          <p>
                            <i class="fa-solid fa-briefcase"></i>&nbsp; Full
                            Time
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        {/* <p>{item.job_description}</p>{' '} */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.job_description,
                          }}
                        />
                      </Row>
                      <Row>
                        <Col>
                          <div>Launch Date</div>
                          <Badge bg="secondary">{item.start_date}</Badge>
                        </Col>
                        <Col>
                          <div>Requirement</div>
                          <Badge bg="secondary">{item.qualification}</Badge>
                        </Col>

                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => EditJobHandler(item)}
                          >
                            Edit
                          </button>
                        </Col>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => DeleteJobHandler(item)}
                          >
                            Delete
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <button
                            className="e-btn"
                            onClick={() => AppliedbyJobHandler(item)}
                          >
                            Applied By
                          </button>
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                );
              })}
            </Card>
          </Col>
          <Col>
            <Card className="MainCard">
              {editMode == false ? (
                <>
                  <Col>
                    <h1>Add Job Listing</h1>
                  </Col>
                </>
              ) : (
                <>
                  <Container>
                    <Row>
                      <Col>
                        <h1>Edit Job Listing</h1>
                      </Col>
                      <Col>
                        <button
                          className="btn btn-success marginTop5"
                          onClick={switchtoaddmode}
                        >
                          Switch To Add Mode
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
              {/* <input
                placeholder="Job Code"
                className="form-control marginTop5"
                type="text"
                name="job_code"
                value={jobDetails.job_code}
                onChange={jobDetailsonChange}
              /> */}
              <Container>
                <input
                  placeholder="Job Title"
                  className="form-control marginTop5"
                  type="text"
                  name="job_title"
                  value={jobDetails.job_title}
                  onChange={jobDetailsonChange}
                />
                <input
                  placeholder="Number of Openings"
                  className="form-control marginTop5"
                  type="text"
                  name="number_of_opening"
                  value={jobDetails.number_of_opening}
                  onChange={jobDetailsonChange}
                />
                <input
                  placeholder="Listed by"
                  className="form-control marginTop5"
                  type="text"
                  name="listed_by"
                  value={jobDetails.listed_by}
                  onChange={jobDetailsonChange}
                  //disabled
                />
                {/* <input
                placeholder="Job Function"
                className="form-control marginTop5"
                type="text"
                name="job_function"
                value={jobDetails.job_function}
                onChange={jobDetailsonChange}
              /> */}
                <select
                  name="job_function"
                  className="form-control"
                  value={jobDetails.job_function}
                  onChange={jobDetailsonChange}
                  //onChange={(e) => InterpersonalskillshandleChange(index, e)}
                  placeholder="Job Function (Categories)"
                >
                  <option name="" value="" style={{ display: "none" }}>
                    Choose Category
                  </option>
                  <option
                    name="Business Operations"
                    value="Business Operations"
                  >
                    Business Operations
                  </option>
                  <option name="Core Engineering" value="Core Engineering">
                    Core Engineering
                  </option>
                  <option
                    name="Information Technology"
                    value="Information Technology"
                  >
                    Information Technology
                  </option>
                  <option name="Human Resources" value="Human Resources">
                    Human Resources
                  </option>
                  <option name="Marketing" value="Marketing ">
                    Marketing
                  </option>
                  <option name="Sales" value="Sales">
                    Sales
                  </option>
                  <option name="Others" value="Others">
                    Others
                  </option>
                </select>
                <input
                  placeholder="Qualification"
                  className="form-control marginTop5"
                  type="text"
                  name="qualification"
                  value={jobDetails.qualification}
                  onChange={jobDetailsonChange}
                />
                <select
                  name="type"
                  className="form-control"
                  value={jobDetails.exprience_category}
                  onChange={jobDetailsonChange}
                  //onChange={(e) => InterpersonalskillshandleChange(index, e)}
                  placeholder="Choose Job Type"
                >
                  <option name="" value="" style={{ display: "none" }}>
                    Choose Exprience Level
                  </option>
                  <option name="Fresher" value="Fresher">
                    Fresher
                  </option>
                  <option name="1-2" value="1-2">
                    1-2
                  </option>
                  <option name="2-4" value="2-4">
                    2-4
                  </option>
                  <option name="4+" value="4+">
                    4+
                  </option>
                </select>
                {/* <input
                placeholder="type"
                className="form-control marginTop5"
                type="text"
                name="type"
                value={jobDetails.type}
                onChange={jobDetailsonChange}
              /> */}
                <select
                  name="type"
                  className="form-control"
                  value={jobDetails.type}
                  onChange={jobDetailsonChange}
                  //onChange={(e) => InterpersonalskillshandleChange(index, e)}
                  placeholder="Choose Job Type"
                >
                  <option name="" value="" style={{ display: "none" }}>
                    Choose Job Type
                  </option>
                  <option name="Full Time" value="Full Time">
                    Full Time
                  </option>
                  <option name="Part Time" value="Part Time">
                    Part Time
                  </option>
                  <option name="Internship" value="Internship">
                    Internship
                  </option>
                </select>
                <input
                  placeholder="Location"
                  className="form-control marginTop5"
                  type="text"
                  name="location"
                  value={jobDetails.location}
                  onChange={jobDetailsonChange}
                />
                <input
                  placeholder="Start Date"
                  className="form-control marginTop5"
                  type="text"
                  name="start_date"
                  value={jobDetails.start_date}
                  onChange={jobDetailsonChange}
                />
                <Row>
                  <Col sm={8}>
                    <input
                      placeholder="3.6"
                      className="form-control marginTop5"
                      type="number"
                      name="salary"
                      value={jobDetails.salary}
                      onChange={jobDetailsonChange}
                    />
                  </Col>
                  <Col sm={4}>
                    <div>
                      <p style={{ paddingTop: 20 }}>
                        <b>in Lakhs</b>
                      </p>
                    </div>
                  </Col>
                </Row>

                {/* <input
                placeholder="Job Description"
                className="form-control marginTop5"
                type="text"
                name="job_description"
                value={jobDetails.job_description}
                onChange={jobDetailsonChange}
              /> */}
                {/* <textarea
                  placeholder="Job Description"
                  className="form-control marginTop5"
                  id="jobdescription"
                  rows="10"
                  cols="35"
                  value={jobDetails.job_description}
                  onChange={(e) => jobDescriptiononchange(e)} 
                  //onChange={jobDetailsonChange}
                /></textarea>*/}

                <JoditEditor
                  ref={editor}
                  value={jobDetails.job_description}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => richtext(newContent)}
                />
                <select
                  name="isremote"
                  className="form-control"
                  value={jobDetails.isremote}
                  onChange={jobDetailsonChange}
                  placeholder="Is Job Remote"
                >
                  <option name="" value="" style={{ display: "none" }}>
                    Is Job Remote
                  </option>
                  <option name="true" value="true">
                    True (Is Job New - AutoFilled)
                  </option>
                  <option name="false" value="false">
                    False
                  </option>
                </select>
                {/* <input
                  placeholder="Is Remote Available"
                  className="form-control marginTop5"
                  type="text"
                  name="isremote"
                  value={jobDetails.isremote}
                  onChange={jobDetailsonChange}
                /> */}
                {/* <input
                  placeholder="IS Job New"
                  className="form-control marginTop5"
                  type="text"
                  name="isjobnew"
                  value={jobDetails.isjobnew}
                  onChange={jobDetailsonChange}
                /> */}
                {/* <select
                  name="isjobnew"
                  className="form-control"
                  value={jobDetails.isjobnew}
                  onChange={jobDetailsonChange}
                  placeholder="Choose Job Type"
                >
                  <option name="" value="" style={{ display: "none" }}>
                    is Job New
                  </option>
                  <option name="true" value="true">
                    True (Is Job New - AutoFilled)
                  </option>
                  <option name="false" value="false">
                    False
                  </option>
                </select> */}
                <select
                  name="isjobactive"
                  className="form-control"
                  value={jobDetails.isjobactive}
                  onChange={jobDetailsonChange}
                  placeholder="Is job Active"
                >
                  <option name="" value="" style={{ display: "none" }}>
                    is Job Active
                  </option>
                  <option name="true" value="true">
                    Active (Active - AutoFilled)
                  </option>
                  <option name="false" value="false">
                    Closed
                  </option>
                </select>
              </Container>
              {editMode == false ? (
                <>
                  <button
                    className="btn btn-success marginTop5"
                    onClick={jobsubmit}
                  >
                    Add Submit
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-success marginTop5"
                  onClick={EditJobSubmit}
                >
                  Edit Submit
                </button>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      {/* </>
        ) : null}
      </Container> */}
    </>
  );
};
export default AddJob;
